import React, { useState, useEffect, useCallback } from "react";
import Sidebar from "../components/Sidebar";
import Register from "../components/Register";
import "./Users.css"; // Import the CSS file we'll create

// EditUserModal Component
function EditUserModal({ user, onClose, onSave }) {
  const [name, setName] = useState(user.name);
  const [email, setEmail] = useState(user.email);
  const [status, setStatus] = useState(user.deleted ? "Inactive" : "Active");
  const [role, setRole] = useState(user.role);
  const [company, setCompany] = useState(user.company);

  const handleSubmit = async () => {
    const updatedUser = {
      name,
      email,
      status: status === "Active" ? false : true,
      role,
      company,
    };

    try {
      const token = localStorage.getItem("token");
      const myHeaders = new Headers();
      myHeaders.append("x-auth-token", token);
      myHeaders.append("Content-Type", "application/json");

      const requestOptions = {
        method: "PUT",
        headers: myHeaders,
        body: JSON.stringify(updatedUser),
        redirect: "follow",
      };

      const response = await fetch(
        `https://api.mrmechanic.co/api/auth/users/${user._id}`,
        requestOptions
      );

      if (response.ok) {
        const result = await response.json();
        onSave(result);
      } else {
        console.error("Failed to update user:", response.statusText);
      }
    } catch (error) {
      console.error("Error updating user:", error);
    }

    onClose();
  };

  return (
    <>
      <div className="modal-overlay" onClick={onClose} />
      <div className="modal-container edit-user-modal">
        <h2 className="modal-title">Edit User</h2>
        <div className="form-group">
          <label className="form-label">Name:</label>
          <input
            type="text"
            value={name}
            className="form-input"
            onChange={(e) => setName(e.target.value)}
          />
        </div>
        <div className="form-group">
          <label className="form-label">Email:</label>
          <input
            type="email"
            value={email}
            className="form-input"
            onChange={(e) => setEmail(e.target.value)}
          />
        </div>
        <div className="form-group">
          <label className="form-label">Status:</label>
          <input
            type="text"
            value={status}
            className="form-input"
            onChange={(e) => setStatus(e.target.value)}
            disabled
          />
        </div>
        <div className="form-group">
          <label className="form-label">Role:</label>
          <select
            value={role}
            onChange={(e) => setRole(e.target.value)}
            className="form-input"
          >
            <option value="customer">Customer</option>
            <option value="partner">Partner</option>
            <option value="admin">Admin</option>
            <option value="superadmin">Superadmin</option>
          </select>
        </div>
        {(role === "partner" || role === "admin") && (
          <div className="form-group">
            <label className="form-label">Company</label>
            <input
              type="text"
              value={company}
              onChange={(e) => setCompany(e.target.value)}
              className="form-input"
              placeholder="Enter your company name"
            />
          </div>
        )}

        <div className="modal-buttons">
          <button onClick={handleSubmit} className="btn btn-primary">
            Save
          </button>
          <button onClick={onClose} className="btn btn-danger">
            Close
          </button>
        </div>
      </div>
    </>
  );
}

function Users() {
  const [users, setUsers] = useState([]);
  const [showRegister, setShowRegister] = useState(false);
  const [showEditModal, setShowEditModal] = useState(false);
  const [selectedUser, setSelectedUser] = useState(null);
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [entriesPerPage, setEntriesPerPage] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);
  const [isMobileView, setIsMobileView] = useState(window.innerWidth <= 768);

  // Check window size for responsive design
  useEffect(() => {
    const handleResize = () => {
      setIsMobileView(window.innerWidth <= 768);
    };
    
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  // Fetch users from API
  const fetchUsers = useCallback(async () => {
    const token = localStorage.getItem("token");
    if (!token) {
      console.error("No token found in localStorage");
      return;
    }

    const myHeaders = new Headers();
    myHeaders.append("x-auth-token", token);

    const requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };

    try {
      const response = await fetch(
        "https://api.mrmechanic.co/api/auth/users",
        requestOptions
      );
      if (response.ok) {
        const data = await response.json();
        setUsers(data);
      } else {
        console.error("Error fetching users:", response.statusText);
      }
    } catch (error) {
      console.error("Error:", error);
    }
  }, []);

  // Fetch users on component mount
  useEffect(() => {
    fetchUsers();
  }, [fetchUsers]);

  const handleShowRegister = () => setShowRegister(true);
  const handleCloseRegister = () => setShowRegister(false);
  const handleCloseEditModal = () => setShowEditModal(false);
  const handleCloseConfirmModal = () => setShowConfirmModal(false);

  // Show confirmation modal to activate/deactivate user
  const handleStatusChangeModal = (user) => {
    setSelectedUser(user);
    setShowConfirmModal(true);
  };

  // Handle the API call to soft delete (change status to active/inactive)
  const handleConfirmStatusChange = async () => {
    const token = localStorage.getItem("token");
    if (!token) {
      console.error("No token found in localStorage");
      return;
    }

    const myHeaders = new Headers();
    myHeaders.append("x-auth-token", token);
    myHeaders.append("Content-Type", "application/json");

    const requestOptions = {
      method: "DELETE",
      headers: myHeaders,
      redirect: "follow",
    };

    try {
      const response = await fetch(
        `https://api.mrmechanic.co/api/auth/users/${selectedUser._id}`,
        requestOptions
      );
      if (response.ok) {
        console.log(`User ${selectedUser.name} status updated.`);
        setShowConfirmModal(false);
        fetchUsers();
      } else {
        console.error("Error updating user status:", response.statusText);
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };

  // Memoized function to handle editing a user
  const handleEditUser = useCallback((user) => {
    setSelectedUser(user);
    setShowEditModal(true);
  }, []);

  // Refresh users list after saving
  const handleSaveUser = useCallback(
    (updatedUser) => {
      const updatedUsers = users.map((user) =>
        user.email === updatedUser.email ? updatedUser : user
      );
      setUsers(updatedUsers);
      fetchUsers();
      setShowEditModal(false);
    },
    [users, fetchUsers]
  );

  const handleSearch = (event) => setSearchTerm(event.target.value);

  const handleEntriesChange = (event) =>
    setEntriesPerPage(parseInt(event.target.value, 10));

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  // Filtered users based on search term
  const filteredUsers = users.filter(
    (user) =>
      user.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
      user.email.toLowerCase().includes(searchTerm.toLowerCase()) ||
      user.deleted
        .toString()
        .toLowerCase()
        .includes(searchTerm.toLowerCase()) ||
      user.role.toLowerCase().includes(searchTerm.toLowerCase()) ||
      (user.company &&
        user.company.toLowerCase().includes(searchTerm.toLowerCase()))
  );

  // Paginate users
  const paginatedUsers = filteredUsers.slice(
    (currentPage - 1) * entriesPerPage,
    currentPage * entriesPerPage
  );

  return (
    <div className="users-page">
      <div className="dashboard-container">
        <Sidebar />
        <div className={`main-content ${isMobileView ? 'mobile-view' : ''}`}>
          <div className="page-header">
            <h2>Users List</h2>
            <button className="btn btn-primary" onClick={handleShowRegister}>
              Add New User
            </button>
          </div>
          
          <div className="search-filter-container">
            <input
              type="text"
              placeholder="Search"
              value={searchTerm}
              onChange={handleSearch}
              className="search-input"
            />
            <div className="entries-select">
              <label>Show entries: </label>
              <select
                value={entriesPerPage}
                onChange={handleEntriesChange}
                className="entries-dropdown"
              >
                <option value={10}>10</option>
                <option value={25}>25</option>
                <option value={50}>50</option>
              </select>
            </div>
          </div>

          <div className="table-responsive">
            <table className="data-table">
              <thead>
                <tr>
                  <th>Avatar</th>
                  <th>Name</th>
                  <th>Email</th>
                  <th>Status</th>
                  <th>Role</th>
                  <th>Company</th>
                  <th>Actions</th>
                </tr>
              </thead>
              <tbody>
                {paginatedUsers.map((user) => (
                  <tr key={user.email}>
                    <td className="avatar-cell">
                      <img
                        src="https://plus.unsplash.com/premium_photo-1669863266283-557f9e170d85?w=600&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8MTd8fGdhbmVzaGF8ZW58MHx8MHx8fDA%3D"
                        alt={user.name}
                        className="avatar-img"
                      />
                    </td>
                    <td>{user.name}</td>
                    <td>{user.email}</td>
                    <td>
                      <span
                        className={`status-badge ${user.deleted ? "inactive" : "active"}`}
                        onClick={() => handleStatusChangeModal(user)}
                      >
                        {user.deleted ? "Inactive" : "Active"}
                      </span>
                    </td>
                    <td>{user.role}</td>
                    <td>{user.company || "N/A"}</td>
                    <td>
                      <button
                        onClick={() => handleEditUser(user)}
                        className="btn btn-edit"
                      >
                        Edit
                      </button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>

          {/* Confirmation Modal for Status Change */}
          {showConfirmModal && selectedUser && (
            <>
              <div className="modal-overlay" onClick={handleCloseConfirmModal} />
              <div className="modal-container confirm-modal">
                <h3 className="modal-title">
                  {selectedUser.deleted
                    ? "Please contact the superadmin to activate the user."
                    : "Are you sure you want to deactivate this user?"}
                </h3>
                <div className="modal-buttons">
                  {!selectedUser.deleted && (
                    <button
                      onClick={handleConfirmStatusChange}
                      className="btn btn-success"
                    >
                      Yes
                    </button>
                  )}
                  <button
                    onClick={handleCloseConfirmModal}
                    className="btn btn-danger"
                  >
                    Close
                  </button>
                </div>
              </div>
            </>
          )}

          <div className="pagination">
            <button
              disabled={currentPage === 1}
              onClick={() => handlePageChange(currentPage - 1)}
              className="btn btn-pagination"
            >
              Previous
            </button>
            <span className="page-info">
              Page {currentPage} of{" "}
              {Math.ceil(filteredUsers.length / entriesPerPage)}
            </span>
            <button
              disabled={
                currentPage === Math.ceil(filteredUsers.length / entriesPerPage)
              }
              onClick={() => handlePageChange(currentPage + 1)}
              className="btn btn-pagination"
            >
              Next
            </button>
          </div>

          {showRegister && (
            <>
              <div className="modal-overlay" onClick={handleCloseRegister} />
              <div className="modal-container register-modal">
                <Register onClose={handleCloseRegister} />
                <div className="modal-close-button">
                  <button
                    onClick={handleCloseRegister}
                    className="btn btn-danger"
                  >
                    Close
                  </button>
                </div>
              </div>
            </>
          )}

          {showEditModal && selectedUser && (
            <EditUserModal
              user={selectedUser}
              onClose={handleCloseEditModal}
              onSave={handleSaveUser}
            />
          )}
        </div>
      </div>
    </div>
  );
}

export default Users;