import React, { useEffect, useState } from "react";
// import FlipPart from "../components/MainPage/FlipPart";
// import CrousalPart from "../components/MainPage/CrousalPart";
// import WhyUs from "../components/MainPage/WhyUs";
// import Network from "../components/MainPage/Network";
import Footer from "../components/MainPage/Footer";
import { useNavigate } from "react-router-dom";
import MainHeader from "../components/MainHeader";
import HeroImage from "../assests/3.jpg";
import BikeImage from "../assests/bikeImage.png";
import RightBike from "../assests/RightBike.png";
import bikeCenter from "../assests/bikeCenter.png";

function Main() {
  const [showStaticText, setShowStaticText] = useState(false);
  const [showAnimatedContent, setShowAnimatedContent] = useState(false);
  const [currentSlide, setCurrentSlide] = useState(0);
  const navigate = useNavigate(); 
  const handleLoginClick = () => {
    navigate("/login"); // Navigate to the /login route
  };

  useEffect(() => {
    const handleScroll = () => {
      const scrollY = window.scrollY;
      setShowStaticText(scrollY > 200);
      setShowAnimatedContent(scrollY > 400);
    };

    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  const bannerStyle = { position: "relative", textAlign: "center", overflow: "hidden",  marginTop: -10, marginBottom: -10};
  const bannerImageStyle = { width: "100%", height: "auto",  maxHeight: "500px",  objectFit: "cover", display: "block", };
  const floatingLeftStyle = { position: "fixed", left: "0", top: "40%", transform: "translateY(-50%)", backgroundColor: "#ff3a00", color: "white", padding: "10px",};
  const floatingRightStyle = { position: "fixed", right: "10px", bottom: "40px",};
  const floatingWhatsAppStyle = { color: "#25D366", fontSize: "30px", textDecoration: "none",};
  const animatedSectionStyle = { display: "flex", justifyContent: "space-between", alignItems: "center", position: "relative", marginInline: 20,  marginBottom: 20};
  const carStyle = { flex: 1,};
  const carTextStyle = { flex: 1,};
  const footerCtaStyle = { position: "fixed", bottom: "0", width: "100%", backgroundColor: "rgb(41, 104, 171)", color: "white", textAlign: "center", padding: "10px 0",};

  return (
    <div>
      {/* Header Section */}
      <MainHeader />

      {/* Main Banner Section */}
      <section style={bannerStyle}>
      <img style={bannerImageStyle} src={HeroImage} alt="Company Logo" />
      </section>

      {/* Animated Car and Text Section */}
      <section style={animatedSectionStyle}>
        <div style={carStyle}>
          <img style={{
            width: 600,
            height: "auto"
          }}
            src={BikeImage} alt="bike"
          />
        </div>
        <div style={carTextStyle}>
        <p style={{ fontFamily: "initial", fontSize: 50, fontWeight: "bold", color: "red", marginBottom: 40}}>
           Our Vision
          </p>
          <p style={{ fontFamily: "initial", fontSize: 20, fontWeight: "400", lineHeight:1.5}}>
          At Mr. mechanic, we envision a world where every superbike enthusiast<br />
          experiences the thrill of the ride with confidence, reliability, and<br />
          unparalleled performance. Our goal is to become the trusted destination<br />
          for superbike repairs, maintenance, and pre-owned sales, delivering<br />
          excellence and passion in everything we do.
          </p>
        </div>
      </section>

      {/* second static section */}

      <section style={{textAlign: "center"}}>
        <p style={{ fontFamily: "initial", fontSize: 50, fontWeight: "bold", color: "rgb(41, 104, 171)", marginBottom: 20}}>Our Services</p>
        <p style={{ fontFamily: "initial", fontSize: 20, fontWeight: "400", lineHeight:1.5, marginBottom: 20, color: "rgb(56, 59, 63)"}}
        >
          We offer a comprehensive range of services designed to meet the needs of every superbike enthusiast. Whether you're looking for expert repairs, regular<br />
          maintenance, or a pre-owned superbike, our team is here to ensure your bike performs at its best.
        </p>
      </section>

      {/* Animated Car and Text Section */}
      <section style={animatedSectionStyle}>
        <div style={carTextStyle}>
          <div style={{ fontFamily: "initial", fontSize: 20, fontWeight: "400", lineHeight: 1.5, marginBottom: 20, color: "rgb(56, 59, 63)"}}>
            <ul style={{ paddingLeft: 20 }}>
              <li style={{margin:15}}>
                <strong>Repairs & Maintenance</strong>
                <br />
                Comprehensive superbike care, including engine tuning, transmission repairs, brake servicing, suspension adjustments, tire replacements, and regular maintenance like oil changes and battery services.
              </li>
              <li style={{margin:15}}>
                <strong>Performance Tuning & Track Prep</strong>
                <br />
                Suspension upgrades, exhaust enhancements, and track preparation services to optimize your superbike's performance for street or facing needs.
                </li>
            </ul>
          </div>
        </div>
        <div style={carTextStyle}>
          <div style={{ fontFamily: "initial", fontSize: 20, fontWeight: "400", lineHeight: 1.5, marginBottom: 20, color: "rgb(56, 59, 63)"}}>
            <ul style={{ paddingLeft: 20 }}>
              <li style={{margin:15}}>
                <strong>Pre-Owned Sales & Customization</strong>
                <br />
                Fully inspected pre-owned superbikes with financing options,<br />
                customization services like paint jobs, seat upgrades, and performance<br />
                mods for a personalized riding experience.
                </li>
              <li style={{margin:15}}>
                <strong>Restorations, Crash Repairs & Expert Advice</strong>
                <br />
                Bike restorations, accident repairs including insurance assistance and<br />
                expert consultations for bike purchases or maintenance tips.
                </li>
            </ul>
          </div>
        </div>
      </section>
      <section style={{ display: "flex", justifyContent: "center", marginTop: -50 }}>
        <img style={{ width: 600, height: "auto"}} src={bikeCenter} alt="bikeCenter" />
      </section>

  {/* why choose us section */}
      <section style={animatedSectionStyle}>
         <div style={carTextStyle}>
            <p style={{ fontFamily: "initial", fontSize: 50, fontWeight: "bold", color: "red", marginBottom: 40}}>
              Why Choose Us ?
              </p>
              <p style={{ fontFamily: "initial", fontSize: 20, fontWeight: "400", lineHeight:1.5}}>
              we are passionate about superbikes and committed to delivering exceptional<br />
              service to every rider. Our expert mechanics, who are also avid riders, provide<br />
              top-quality maintenance, repairs, and custom upgrades in a state-of-the-art<br />
              facility using high-quality parts. We offer thoroughly inspected and<br />
              performance-tested pre-owned bikes, ensuring reliability and value. With<br />
              transparent pricing, fast turnaround times, and a customer-focused<br />
              approach, we build lasting relationships based on trust and satisfaction. Join<br />
              a community of riders who rely on us to keep their bikes in peak condition<br />
              and find their next adventure-ready ride.
              </p>
          </div>
            <div style={carStyle}>
              <img style={{ width: 600, height: "auto"}} src={RightBike} alt="bike"/>
            </div>
          </section>

      {/* <FlipPart />
      <CrousalPart />
      <WhyUs />
      <Network /> */}
      <Footer />
      {/* Floating Sidebars */}
      <div style={floatingLeftStyle}>
        <a
          href="tel:+911001004400"
          style={{ color: "white", textDecoration: "none" }}
        >
          Call Us Today!
        </a>
      </div>
      <div style={floatingRightStyle}>
        <a href="https://wa.me/+911001004400" style={floatingWhatsAppStyle}>
          <i className="fab fa-whatsapp"></i>
        </a>
      </div>

      {/* Footer Call to Action */}
      <footer style={footerCtaStyle}>
        <div className="cta-content">
          <span>Get Started - +91-80-100-44000</span>
        </div>
      </footer>
    </div>
  );
}

export default Main;
