import React, { useContext, useState, useEffect } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import {
  FaUsers,
  FaChartLine,
  FaSignOutAlt,
  FaBox,
  FaWarehouse,
  FaBuilding,
  FaTools,
  FaClipboardList,
  FaPlusSquare,
  FaSearch,
  FaUserTie,
  FaClipboardCheck,
  FaCogs,
  FaBars,
  FaTimes
} from "react-icons/fa";
import AuthContext from "../context/AuthContext";
import logo from "../assests/mrmechanics-logo1.png";
import "./Sidebar.css"; // We'll create this CSS file

const Sidebar = () => {
  const { user, setUser } = useContext(AuthContext);
  const location = useLocation();
  const navigate = useNavigate();
  const [openSection, setOpenSection] = useState(null);
  const [isMobileOpen, setIsMobileOpen] = useState(false);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  // Handle window resize
  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
      if (window.innerWidth > 768) {
        setIsMobileOpen(false);
      }
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const menuItems = [
    { path: "/dashboard", label: "Dashboard", icon: <FaChartLine className="sidebar-icon" /> },
    { path: "/users", label: "Users", icon: <FaUsers className="sidebar-icon" />, role: "superadmin" },
    { path: "/partnerList", label: "Partners List", icon: <FaUsers className="sidebar-icon" />, role: "admin" },
    {
      label: "Inventory",
      icon: <FaBox className="sidebar-icon" />,
      onClick: () => setOpenSection(openSection === "Inventory" ? null : "Inventory"),
      subItems: [
        { path: "/inventory", label: "Inventory Listings", icon: <FaBox className="sidebar-icon" /> },
        { path: "/stock-transfer", label: "Stock Transfer", icon: <FaWarehouse className="sidebar-icon" /> },
        { path: "/inventory-updates", label: "Inventory Updates", icon: <FaBox className="sidebar-icon" />, role: "superadmin" },
        { path: "/inventory/companies", label: "Companies", icon: <FaBuilding className="sidebar-icon" /> },
        { path: "/inventory/locations", label: "Location / Warehouses", icon: <FaWarehouse className="sidebar-icon" /> },
      ],
    },
    {
      label: "Service",
      icon: <FaTools className="sidebar-icon" />,
      onClick: () => setOpenSection(openSection === "Service" ? null : "Service"),
      subItems: [
        { path: "/service/jobcard", label: "Job Cards", icon: <FaClipboardList className="sidebar-icon" /> },
        { path: "/service/create-edit-job-card", label: "New/Edit Job Card", icon: <FaPlusSquare className="sidebar-icon" /> },
        { path: "/search", label: "Search", icon: <FaSearch className="sidebar-icon" /> },
        { path: "/labours", label: "Labours", icon: <FaUserTie className="sidebar-icon" /> },
        { path: "/service-inventory", label: "Service Inventory", icon: <FaCogs className="sidebar-icon" /> },
        { path: "/changelog", label: "Changelog", icon: <FaClipboardCheck className="sidebar-icon" />, role: "superadmin" },
      ],
    },
  ];

  const handleLogout = () => {
    localStorage.removeItem("token");
    setUser(null);
    navigate("/login");
  };

  const toggleMobileMenu = () => {
    setIsMobileOpen(!isMobileOpen);
  };

  const closeMobileMenu = () => {
    if (windowWidth <= 768) {
      setIsMobileOpen(false);
    }
  };

  return (
    <>
      {/* Mobile Toggle Button */}
      <div className="sidebar-toggle" onClick={toggleMobileMenu}>
        {isMobileOpen ? <FaTimes /> : <FaBars />}
      </div>

      <div className={`sidebar ${isMobileOpen ? 'mobile-open' : ''}`}>
        <div className="logo-container">
          <img src={logo} alt="MrMechanics Logo" className="logo" />
        </div>

        <nav className="sidebar-nav">
          <ul className="menu-list">
            {menuItems.map((item) => {
              // Skip items that require a role the user doesn't have
              if (item.role && item.role !== user?.role) return null;
              
              return (
                <React.Fragment key={item.label}>
                  <li 
                    className={`menu-item ${location.pathname === item.path ? 'active' : ''}`}
                    onClick={() => {
                      if (item.onClick) item.onClick();
                      if (item.path) closeMobileMenu();
                    }}
                  >
                    {item.icon}
                    {item.path ? (
                      <Link to={item.path} className="menu-link">
                        {item.label}
                      </Link>
                    ) : (
                      <span className="menu-link">
                        {item.label}
                        <span className={`dropdown-indicator ${openSection === item.label ? 'open' : ''}`}>
                          ▼
                        </span>
                      </span>
                    )}
                  </li>

                  {item.subItems && openSection === item.label && (
                    <ul className="submenu-list">
                      {item.subItems.map((subItem) => {
                        if (subItem.role && subItem.role !== user?.role) return null;
                        
                        return (
                          <li 
                            key={subItem.path}
                            className={`submenu-item ${location.pathname === subItem.path ? 'active' : ''}`}
                            onClick={closeMobileMenu}
                          >
                            {subItem.icon}
                            <Link to={subItem.path} className="menu-link">
                              {subItem.label}
                            </Link>
                          </li>
                        );
                      })}
                    </ul>
                  )}
                </React.Fragment>
              );
            })}
          </ul>
        </nav>

        <button className="logout-button" onClick={handleLogout}>
          <FaSignOutAlt className="sidebar-icon" />
          Logout
        </button>
      </div>

      {/* Overlay for mobile */}
      {isMobileOpen && <div className="sidebar-overlay" onClick={closeMobileMenu}></div>}
    </>
  );
};

export default Sidebar;