import React, { useState, useEffect, useRef, useContext } from "react";
import { useNavigate } from "react-router-dom";
import { useDropzone } from "react-dropzone";
import Navbar from "../components/Navbar";
import Sidebar from "../components/Sidebar";
import AuthContext from "../context/AuthContext";
import { v4 as uuidv4 } from 'uuid';

// Modal component for add inventory input
function InventoryModal({ isOpen, onClose, onSubmit }) {
  const [formData, setFormData] = useState({ name: "", description: "", category: "", SKU: "", company: "", images: [], invoiceDate: "",
    subCategories: [
      { name: "", type: "", quantity: 1, price: 0, location: "",
        // Financial fields
        discountPercentage: 0, taxType: "igst", igstRate: 0, cgstRate: 0, sgstRate: 0, costPrice: 0,
        // These will be calculated automatically
        taxableAmount: 0, totalAmount: 0, totalTaxAmount: 0, finalPrice: 0, cgstAmount: 0, sgstAmount: 0, gstAmount: 0, profitMargin: 0, discountedPricePerUnit: 0, taxAmountPerUnit: 0, finalPricePerUnit: 0, taxPerUnit: 0, finalPriceAfterTax: 0, units: [],
      },
    ],
  });
  const [errors, setErrors] = useState({});
  const [companies, setCompanies] = useState([]);
  const [locations, setLocations] = useState([]);
  const modalRef = useRef();

  useEffect(() => {
    // Fetch companies
    const fetchCompanies = async () => {
      try {
        const response = await fetch("https://api.mrmechanic.co/api/companies", {
          method: "GET",
          headers: {
            "x-auth-token": localStorage.getItem("token"),
            "Content-Type": "application/json",
          },
        });
        if (!response.ok) throw new Error("Failed to fetch companies.");
        const data = await response.json();
        setCompanies(Array.isArray(data) ? data : []);
      } catch (error) {
        console.error("Error fetching companies:", error);
      }
    };

    // Fetch locations
    const fetchLocations = async () => {
      try {
        const response = await fetch("https://api.mrmechanic.co/api/location", {
          method: "GET",
          headers: {
            "x-auth-token": localStorage.getItem("token"),
            "Content-Type": "application/json",
          },
        });
        if (!response.ok) throw new Error("Failed to fetch locations.");
        const data = await response.json();
        setLocations(Array.isArray(data) ? data : []);
      } catch (error) {
        console.error("Error fetching locations:", error);
      }
    };

    fetchCompanies();
    fetchLocations();
  }, []);

  // Handle image upload using react-dropzone
  const handleDrop = (acceptedFiles) => {
    const formDataForImages = new FormData();
    acceptedFiles.forEach((file) => formDataForImages.append("file", file));
    fetch("https://api.mrmechanic.co/api/upload", {
      method: "POST",
      body: formDataForImages,
    })
      .then((res) => res.json())
      .then((data) => {
        if (data.imageUrls) {
          setFormData((prevData) => ({
            ...prevData,
            images: data.imageUrls,
          }));
        }
      })
      .catch((err) => console.error("Error uploading image:", err));
  };

  const { getRootProps, getInputProps } = useDropzone({
    onDrop: handleDrop,
    accept: "image/*",
    multiple: true,
  });

  // Close modal on backdrop click
  const handleBackdropClick = (e) => {
    if (modalRef.current && !modalRef.current.contains(e.target)) {
      onClose();
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleBackdropClick);
    return () => document.removeEventListener("mousedown", handleBackdropClick);
  }, []);

  if (!isOpen) return null;

  // Financial calculations for a subcategory
  const calculateFinancials = (subCategory) => {
    // Set default values
    subCategory.quantity = subCategory.quantity || 0;
    subCategory.discountPercentage = subCategory.discountPercentage || 0;
    subCategory.costPrice = subCategory.costPrice || 0;
    
    // Set tax rates (IGST has priority over CGST/SGST)
    subCategory.igstRate = subCategory.igstRate || 0;
    subCategory.cgstRate = subCategory.cgstRate || 0;
    subCategory.sgstRate = subCategory.sgstRate || 0;
  
    // Calculate base price after discount (per unit)
    // Example: 100 * (1 - 10/100) = 90
    const priceAfterDiscount = subCategory.costPrice * (1 - subCategory.discountPercentage / 100);
  
    // Calculate the computed price using profitMargin
    if (typeof subCategory.profitMargin !== 'undefined' && subCategory.costPrice) {
      subCategory.price = priceAfterDiscount * (1 + subCategory.profitMargin / 100);
    } else {
      subCategory.price = priceAfterDiscount;
    }
  
    // Calculate taxable amount WITHOUT profit margin
    // Use priceAfterDiscount instead of price for taxable amount
    subCategory.taxableAmount = priceAfterDiscount * subCategory.quantity;
  
    if (subCategory.taxType === "igst") {
      subCategory.gstAmount = (subCategory.taxableAmount * subCategory.igstRate) / 100;
      subCategory.cgstAmount = 0;
      subCategory.sgstAmount = 0;
    } else if (subCategory.taxType === "cgst_sgst") {
      subCategory.cgstAmount = (subCategory.taxableAmount * subCategory.cgstRate) / 100;
      subCategory.sgstAmount = (subCategory.taxableAmount * subCategory.sgstRate) / 100;
      subCategory.gstAmount = subCategory.cgstAmount + subCategory.sgstAmount;
    } else {
      subCategory.gstAmount = 0;
      subCategory.cgstAmount = 0;
      subCategory.sgstAmount = 0;
    }

    // Calculate final prices (for all units)
    subCategory.totalTaxAmount = subCategory.gstAmount;
    // Use the price with profit margin for final price calculation
    subCategory.finalPrice = subCategory.price * subCategory.quantity + subCategory.totalTaxAmount;
    
    // Per-unit calculations
    subCategory.discountedPricePerUnit = priceAfterDiscount; // remains the discounted costPrice
    subCategory.taxAmountPerUnit = subCategory.quantity > 0 ? subCategory.gstAmount / subCategory.quantity : 0;
    subCategory.finalPricePerUnit = subCategory.price + subCategory.taxAmountPerUnit;
    
    // Explicitly assign per-unit tax and final price after tax properties
    subCategory.taxPerUnit = subCategory.taxAmountPerUnit;
    subCategory.finalPriceAfterTax = subCategory.finalPricePerUnit;
  
    // (Optional) Calculate profit margins based on costPrice
    if (subCategory.costPrice) {
      const totalCost = subCategory.costPrice * subCategory.quantity;
      subCategory.calculatedProfitMargin = ((subCategory.finalPrice - totalCost) / totalCost) * 100;
      const profitPerUnit = subCategory.finalPricePerUnit - subCategory.costPrice;
      subCategory.profitMarginPerUnit = (profitPerUnit / subCategory.costPrice) * 100;
    }
    
    // Generate unique units if not already generated
    subCategory.units =
      subCategory.units && subCategory.units.length > 0
        ? subCategory.units
        : Array.from({ length: subCategory.quantity }, () => ({
            uniqueId: uuidv4(),
            location: subCategory.location,
          }));
  
    return {
      totalAmount: parseFloat((subCategory.price * subCategory.quantity).toFixed(2)),
      taxableAmount: parseFloat(subCategory.taxableAmount.toFixed(2)),
      totalTaxAmount: parseFloat(subCategory.gstAmount.toFixed(2)),
      finalPrice: parseFloat(subCategory.finalPrice.toFixed(2)),
      igstAmount: parseFloat((subCategory.taxType === "igst" ? subCategory.gstAmount : 0).toFixed(2)),
      cgstAmount: parseFloat((subCategory.taxType === "cgst_sgst" ? subCategory.cgstAmount : 0).toFixed(2)),
      sgstAmount: parseFloat((subCategory.taxType === "cgst_sgst" ? subCategory.sgstAmount : 0).toFixed(2)),
      gstAmount: parseFloat(subCategory.gstAmount.toFixed(2)),
      discountedPricePerUnit: parseFloat(priceAfterDiscount.toFixed(2)),
      taxAmountPerUnit: parseFloat(subCategory.taxAmountPerUnit.toFixed(2)),
      finalPricePerUnit: parseFloat(subCategory.finalPricePerUnit.toFixed(2)),
      taxPerUnit: parseFloat(subCategory.taxPerUnit.toFixed(2)),
      finalPriceAfterTax: parseFloat(subCategory.finalPriceAfterTax.toFixed(2)),
      units: subCategory.units,
      unit: subCategory.type === "liquid" ? "ML" : "Unit",
    };
  };    

  const handleSubCategoryChange = (index, e) => {
    const { name, value } = e.target;
    const newSubCategories = [...formData.subCategories];
    newSubCategories[index] = {
      ...newSubCategories[index],
      [name]: value,
    };

    // When values change, recalculate financials
    const calculatedDetails = calculateFinancials(newSubCategories[index]);
    newSubCategories[index] = {
      ...newSubCategories[index],
      ...calculatedDetails,
    };

    setFormData((prevData) => ({
      ...prevData,
      subCategories: newSubCategories,
    }));
  };

const handleTaxTypeChange = (index, taxType) => {
  const newSubCategories = [...formData.subCategories];
  newSubCategories[index] = {
    ...newSubCategories[index],
    taxType: taxType
  };

  // Reset rates based on tax type
  if (taxType === "igst") {
    newSubCategories[index].cgstRate = 0;
    newSubCategories[index].sgstRate = 0;
  } else if (taxType === "cgst_sgst") {
    newSubCategories[index].igstRate = 0;
  }

  // Recalculate financials with updated values
  const calculatedDetails = calculateFinancials(newSubCategories[index]);
  newSubCategories[index] = { 
    ...newSubCategories[index], 
    ...calculatedDetails 
  };

  setFormData(prevData => ({
    ...prevData,
    subCategories: newSubCategories
  }));
};

  const addSubCategory = () => {
    setFormData((prevData) => ({
      ...prevData,
      subCategories: [
        ...prevData.subCategories,
        { name: "", type: "", quantity: 1, price: 0, location: "", unit: "Unit", discountPercentage: 0, taxType: "igst", igstRate: 0, cgstRate: 0, sgstRate: 0, costPrice: 0, taxableAmount: 0, totalAmount: 0, totalTaxAmount: 0, finalPrice: 0, cgstAmount: 0, sgstAmount: 0, gstAmount: 0, profitMargin: 0, discountedPricePerUnit: 0, taxAmountPerUnit: 0, finalPricePerUnit: 0, taxPerUnit: 0, finalPriceAfterTax: 0, units: [],
        },
      ],
    }));
  };
  const toggleMeasurement = (index) => {
    const newSubCategories = [...formData.subCategories];
    newSubCategories[index].measurement =
      newSubCategories[index].measurement === "unit" ? "mL" : "unit"; 
    setFormData({
      ...formData,
      subCategories: newSubCategories,
    });
  };

  const validateForm = () => {
    let formErrors = {};

    if (!formData.name) formErrors.name = "Part Name is required";
    if (!formData.description)
      formErrors.description = "Description is required";
    if (!formData.category) formErrors.category = "Category is required";
    if (!formData.SKU) formErrors.SKU = "HSN Code is required";
    if (!formData.company) formErrors.company = "Company is required";

    formData.subCategories.forEach((subCategory, index) => {
      if (!subCategory.name)
        formErrors[`subCategoryName${index}`] =
          "Subcategory Name is required";
      if (!subCategory.type)
        formErrors[`subCategoryType${index}`] = "Item Type is required";
      if (!subCategory.quantity || subCategory.quantity <= 0)
        formErrors[`subCategoryQuantity${index}`] =
          "Quantity must be greater than 0";
      if (!subCategory.price || subCategory.price < 0)
        formErrors[`subCategoryPrice${index}`] = "Price must be 0 or greater";
      if (!subCategory.location)
        formErrors[`subCategoryLocation${index}`] = "Location is required";
    });

    setErrors(formErrors);
    return Object.keys(formErrors).length === 0;
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  // API integration: on form submit, prepare the data and call the API endpoint
  const handleSubmit = async () => {
    if (validateForm()) {
      const preparedData = {
        ...formData,
        subCategories: formData.subCategories.map((subCategory) => ({
          ...subCategory,
          // Ensure units are generated
          units:
            subCategory.units && subCategory.units.length > 0
              ? subCategory.units
              : Array.from({ length: subCategory.quantity }, () => ({
                  uniqueId: uuidv4(),
                  location: subCategory.location,
                })),
        })),
      };

      try {
        const response = await fetch("https://api.mrmechanic.co/api/inventory", {
          method: "POST",
          headers: {
            "x-auth-token": localStorage.getItem("token"),
            "Content-Type": "application/json",
          },
          body: JSON.stringify(preparedData),
        });
        if (!response.ok) {
          throw new Error("Failed to create inventory");
        }
        const result = await response.json();
        console.log("API Submission Successful:", result);
        // Optionally, call onSubmit(result) to update the parent or clear form
        onSubmit && onSubmit(result);
        onClose();
      } catch (error) {
        console.error("Error submitting data:", error);
      }
    } else {
      // If validation fails, scroll to the first error
      const firstErrorField = Object.keys(errors)[0];
      const element = document.getElementById(firstErrorField);
      if (element) {
        element.scrollIntoView({ behavior: "smooth", block: "center" });
      }
    }
  };

  // Inline modal styles…
  const modalStyle = { display: "flex", justifyContent: "center", alignItems: "center", position: "fixed", top: 0, left: 0, right: 0, bottom: 0, backgroundColor: "rgba(0, 0, 0, 0.5)", zIndex: 1000,};
  const modalContentStyle = { backgroundColor: "#fff", padding: "20px", borderRadius: "8px", boxShadow: "0 4px 8px rgba(0, 0, 0, 0.2)", width: "90%", maxWidth: "1000px", maxHeight: "90vh", overflowY: "auto", display: "flex", flexDirection: "column", margin: "0 auto",};
  const inputStyle = { width: "98%", padding: "12px", margin: "10px 0", borderRadius: "4px", border: "1px solid #ccc",};
  const labelStyle = { display: "block", margin: "5px 0", fontWeight: "bold" };
  const errorStyle = { color: "red", fontSize: "12px" };
  const toggleButtonStyle = { padding: "10px 20px", margin: "10px 0", border: "none", borderRadius: "4px", backgroundColor: "#4CAF50", color: "white", cursor: "pointer", textAlign: "center",};

  return (
  <div style={modalStyle}>
    <div style={modalContentStyle} ref={modalRef}>
      <h2>Add Inventory</h2>
      <form>
        <div style={{ display: "flex", gap: "40px", marginBottom: "10px" }}>
          {["name", "description", "category"].map(field => (
            <div key={field} style={{ flex: 1 }}>
              <label style={labelStyle}>{field.charAt(0).toUpperCase() + field.slice(1)}</label>
              <input
                type="text"
                name={field}
                value={formData[field]}
                onChange={handleInputChange}
                placeholder={`Enter ${field}`}
                style={inputStyle}
              />
              {errors[field] && <div style={errorStyle} id={field}>{errors[field]}</div>}
            </div>
          ))}
        </div>

        {formData.subCategories.map((subCategory, index) => (
          <div key={index} style={{ marginBottom: "20px", padding: "15px", border: "1px solid #eee", borderRadius: "5px" }}>
            <h4>Subcategory {index + 1}</h4>
            <div style={{ display: "flex", gap: "40px", marginBottom: "10px" }}>
              {[
                { name: "name", label: "Subcategory Name", type: "text" },
                { name: "quantity", label: "Quantity", type: "number" },
                { name: "costPrice", label: "Cost per item", type: "number" },
                { name: "profitMargin", label: "Profit Margin (%)", type: "number" }
              ].map(({ name, label, type }) => (
                <div key={name} style={{ flex: 1 }}>
                  <label style={labelStyle}>{label}</label>
                  <input
                    type={type}
                    name={name}
                    value={subCategory[name]}
                    onChange={(e) => handleSubCategoryChange(index, e)}
                    placeholder={`Enter ${label.toLowerCase()}`}
                    style={inputStyle}
                  />
                  {errors[`subCategory${name.charAt(0).toUpperCase() + name.slice(1)}${index}`] && (
                    <div style={errorStyle} id={`subCategory${name.charAt(0).toUpperCase() + name.slice(1)}${index}`}>
                      {errors[`subCategory${name.charAt(0).toUpperCase() + name.slice(1)}${index}`]}
                    </div>
                  )}
                </div>
              ))}
            </div>

            <div style={{ marginBottom: "15px", padding: "10px", backgroundColor: "#f9f9f9", borderRadius: "5px",}}>
                <div style={{ display: "flex", gap: "40px", marginBottom: "10px" }}>
                  <div style={{ flex: 1 }}>
                    <label style={labelStyle}>Discount Percentage</label>
                    <input
                      type="number"
                      name="discountPercentage"
                      value={subCategory.discountPercentage}
                      onChange={(e) => handleSubCategoryChange(index, e)}
                      style={inputStyle}
                    />
                  </div>            
                </div>                
                <div style={{ marginBottom: "10px" }}>
        <label style={labelStyle}>Tax Type</label>
        <div style={{ display: "flex", gap: "20px", margin: "10px 0" }}>
          <label style={{ display: "flex", alignItems: "center" }}>
            <input
              type="radio"
              name={`taxType-${index}`}  // Unique group name per sub-category
              value="igst"
              checked={subCategory.taxType === "igst"}
              onChange={() => handleTaxTypeChange(index, "igst")}
              style={{ marginRight: "5px" }}
            />
            IGST
          </label>
          <label style={{ display: "flex", alignItems: "center" }}>
            <input
              type="radio"
              name={`taxType-${index}`}  // Same group name for pair
              value="cgst_sgst"
              checked={subCategory.taxType === "cgst_sgst"}
              onChange={() => handleTaxTypeChange(index, "cgst_sgst")}
              style={{ marginRight: "5px" }}
            />
            CGST + SGST
          </label>
        </div>
      </div>
                {subCategory.taxType === "igst" ? (
                  <div style={{ display: "flex", gap: "40px", marginBottom: "10px" }}>
                    <div style={{ flex: 1 }}>
                      <label style={labelStyle}>IGST Rate (%)</label>
                      <input
                        type="number"
                        name="igstRate"
                        value={subCategory.igstRate}
                        onChange={(e) => handleSubCategoryChange(index, e)}
                        style={inputStyle}
                      />
                    </div>                    
                  </div>
                ) : (
                  <div style={{ display: "flex", gap: "40px", marginBottom: "10px" }}>
                    <div style={{ flex: 1 }}>
                      <label style={labelStyle}>CGST Rate (%)</label>
                      <input
                        type="number"
                        name="cgstRate"
                        value={subCategory.cgstRate}
                        onChange={(e) => handleSubCategoryChange(index, e)}
                        style={inputStyle}
                      />
                    </div>
                    <div style={{ flex: 1 }}>
                      <label style={labelStyle}>CGST Amount</label>
                      <input
                        type="number"
                        name="cgstAmount"
                        value={subCategory.cgstAmount || ""}
                        readOnly
                        style={{ ...inputStyle, backgroundColor: "#f0f0f0" }}
                      />
                    </div>
                    <div style={{ flex: 1 }}>
                      <label style={labelStyle}>SGST Rate (%)</label>
                      <input
                        type="number"
                        name="sgstRate"
                        value={subCategory.sgstRate}
                        onChange={(e) => handleSubCategoryChange(index, e)}
                        style={inputStyle}
                      />
                    </div>
                    <div style={{ flex: 1 }}>
                      <label style={labelStyle}>SGST Amount</label>
                      <input
                        type="number"
                        name="sgstAmount"
                        value={subCategory.sgstAmount || ""}
                        readOnly
                        style={{ ...inputStyle, backgroundColor: "#f0f0f0" }}
                      />
                    </div>
                  </div>
                )}
                <div>
                 <label>GST Amount</label>
                  <input
                    type="number"
                    value={subCategory.totalTaxAmount}
                    readOnly
                    style={{ ...inputStyle, backgroundColor: "#f0f0f0" }}
                  />
                  <label>Taxable Amount</label>
                  <input
                    type="number"
                    value={subCategory.taxableAmount}
                    readOnly
                    style={{ ...inputStyle, backgroundColor: "#f0f0f0" }}
                  />
                 
                  <label>Final Price</label>
                  <input
                    type="number"
                    value={subCategory.finalPrice}
                    readOnly
                    style={{ ...inputStyle, backgroundColor: "#f0f0f0" }}
                  />                 
                </div>
              </div>
               <div style={{ display: "flex", gap: "40px", marginBottom: "10px" }}>
                <div style={{ flex: 1 }}>
                  <label style={labelStyle}>Invoice Number</label>
                  <input
                    type="text"
                    name="uniqueId"
                    value={subCategory.uniqueId || ""}
                    onChange={(e) => handleSubCategoryChange(index, e)}
                    placeholder="Enter Invoice Number"
                    style={inputStyle}
                  />
                </div>
                <div style={{ flex: 1 }}>
                  <label style={labelStyle}>Item Type</label>
                  <select
                    name="type"
                    value={subCategory.type}
                    onChange={(e) => handleSubCategoryChange(index, e)}
                    style={inputStyle}
                  >
                    <option value="" disabled>
                      Select type
                    </option>
                    <option value="solid">Solid</option>
                    <option value="liquid">Liquid</option>
                  </select>
                </div>
                {/* Measurement */}
                <div style={{ flex: 1 }}>
                    <label style={labelStyle}>Measurement</label>
                    <div>
                      <span style={{ marginRight: "10px" }}>{subCategory.measurement}</span>
                      <button type="button" onClick={() => toggleMeasurement(index)} style={toggleButtonStyle}>
                        Toggle
                      </button>
                    </div>
                  </div>
              </div>
              <label style={labelStyle}>Location</label>
              <select
                name="location"
                value={subCategory.location}
                onChange={(e) => handleSubCategoryChange(index, e)}
                style={inputStyle}
              >
                <option value="">Select a location</option>
                {locations.map((location) => (
                  <option key={location._id} value={location._id}>
                    {location.name}
                  </option>
                ))}
              </select>
            </div>
          ))}
          <button type="button" onClick={addSubCategory} style={toggleButtonStyle}>
            Add Subcategory
          </button>

        {/* Image upload section */}
        <label style={labelStyle}>Images</label>
        <div {...getRootProps()} style={{ border: "2px dashed #ccc", padding: "20px", textAlign: "center", cursor: "pointer" }}>
          <input {...getInputProps()} />
          <p>Drag & drop images here, or click to select images</p>
        </div>

        {/* HSN Code and Company section */}
        <div style={{ display: "flex", gap: "40px", marginBottom: "10px" }}>
          <div style={{ flex: 1 }}>
            <label style={labelStyle}>HSN Code</label>
            <input
              type="text"
              name="SKU"
              value={formData.SKU}
              onChange={handleInputChange}
              placeholder="Enter HSN Code"
              style={inputStyle}
            />
          </div>
          <div style={{ flex: 1 }}>
            <label style={labelStyle}>Company</label>
            <select name="company" value={formData.company} onChange={handleInputChange} style={inputStyle}>
              <option value="">Select a company</option>
              {companies.map((company) => (
                <option key={company._id} value={company._id}>{company.name}</option>
              ))}
            </select>
          </div>
          <div style={{ flex: 1 }}>
            <label style={labelStyle}>Invoice Date</label>
            <input
              type="date"
              name="invoiceDate"
              value={formData.invoiceDate}
              onChange={handleInputChange}
              style={inputStyle}
            />
          </div>
        </div>
        <button type="button" onClick={handleSubmit} style={toggleButtonStyle}>Submit</button>
      </form>
      <button onClick={onClose} style={{ ...toggleButtonStyle, backgroundColor: "#f44336" }}>Close</button>
    </div>
  </div>
);
}

function TransferModal({ isOpen, onClose, subCategories, selectedSubCategory, onSubmit, setSelectedSubCategory,
}) {
  const [formData, setFormData] = useState({ subCategoryName: "", newLocation: "", transferQuantity: 0,});
  const [errors, setErrors] = useState({});
  useEffect(() => {
    if (subCategories.length > 0) {
      setFormData({
        subCategoryName: subCategories[0].name, // Default to the first sub-category
        newLocation: subCategories[0].location,
        transferQuantity: 0, // Reset transfer quantity
      });
    }
  }, [subCategories]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const validateForm = () => {
    const newErrors = {};

    if (!formData.subCategoryName) {
      newErrors.subCategoryName = "Sub-category is required.";
    }

    if (!formData.newLocation) {
      newErrors.newLocation = "New location is required.";
    }

    if (
      !formData.transferQuantity ||
      formData.transferQuantity <= 0 ||
      formData.transferQuantity > selectedSubCategory?.availableQuantity
    ) {
      newErrors.transferQuantity =
        `Transfer quantity must be between 1 and ${selectedSubCategory?.availableQuantity || 0}.`;
    }

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleSubmit = () => {
    if (!validateForm()) {
      return;
    }

    onSubmit({ ...formData });
  };

  if (!isOpen) return null;

  return (
    <div
      className="modal"
      style={{display: "flex",position: "fixed",top: 0,left: 0,width: "100%",height: "100%",justifyContent: "center",alignItems: "center",backgroundColor: "rgba(0, 0, 0, 0.7)",zIndex: 1000,
      }}
    >
      <div
        className="modal-content"
        style={{backgroundColor: "#f9f9f9",padding: "30px",borderRadius: "10px",boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",width: "500px",maxWidth: "90%",
        }}
      >
        <h2 style={{ color: "#333", fontSize: "22px", marginBottom: "20px" }}>
          Transfer Stock
        </h2>

        <label style={{ display: "block", marginBottom: "15px" }}>
          <span
            style={{fontSize: "14px",color: "#333",display: "block",marginBottom: "5px",
            }}
          >
            Sub-Category:
          </span>
          <select
            name="subCategoryName"
            value={formData.subCategoryName}
            onChange={(e) => {
              const selectedName = e.target.value;
              const selected = subCategories.find(
                (sub) => sub.name === selectedName
              );
              setSelectedSubCategory(selected); // Update the selected sub-category
              handleInputChange(e); // Update form data
            }}
            style={{ width: "100%", padding: "10px", borderRadius: "6px", border: "1px solid #ddd", backgroundColor: "#f9f9f9", color: "#555", fontSize: "14px",
            }}
          >
            {subCategories.map((sub) => (
              <option key={sub.name} value={sub.name}>
                {sub.name}
              </option>
            ))}
          </select>
          {errors.subCategoryName && (
            <div style={{ color: "red", fontSize: "12px", marginTop: "5px" }}>
              {errors.subCategoryName}
            </div>
          )}
        </label>

        <label style={{ display: "block", marginBottom: "15px" }}>
          <span
            style={{
              fontSize: "14px",
              color: "#333",
              display: "block",
              marginBottom: "5px",
            }}
          >
            New Location:
          </span>
          <select
            name="newLocation"
            value={formData.newLocation}
            onChange={handleInputChange}
            style={{
              width: "100%",
              padding: "10px",
              borderRadius: "6px",
              border: "1px solid #ddd",
              backgroundColor: "#f9f9f9",
              color: "#555",
              fontSize: "14px",
            }}
          >
            {subCategories.map((sub) => (
              <option key={sub.location} value={sub.location}>
                {sub.location}
              </option>
            ))}
          </select>
          {errors.newLocation && (
            <div style={{ color: "red", fontSize: "12px", marginTop: "5px" }}>
              {errors.newLocation}
            </div>
          )}
        </label>

        <label style={{ display: "block", marginBottom: "20px" }}>
          <span>
            Transfer Quantity (Max: {selectedSubCategory?.availableQuantity || 0}):
          </span>
          <input
            type="number"
            name="transferQuantity"
            value={formData.transferQuantity}
            onChange={(e) => {
              const quantity = Number(e.target.value);
              handleInputChange(e);
            }}
            style={{ width: "100%", padding: "10px", borderRadius: "6px", border: "1px solid #ddd", backgroundColor: "#f9f9f9", color: "#555", fontSize: "14px",
            }}
          />
          {errors.transferQuantity && (
            <div style={{ color: "red", fontSize: "12px", marginTop: "5px" }}>
              {errors.transferQuantity}
            </div>
          )}
        </label>

        <div
          style={{ display: "flex", justifyContent: "flex-end", gap: "10px" }}
        >
          <button
            onClick={handleSubmit}
            style={{ padding: "10px 20px", backgroundColor: "#28a745", color: "#fff", border: "none", borderRadius: "6px", cursor: "pointer", fontSize: "14px",
            }}
          >
            Submit
          </button>
          <button
            onClick={onClose}
            style={{ padding: "10px 20px", backgroundColor: "#dc3545", color: "#fff", border: "none", borderRadius: "6px", cursor: "pointer", fontSize: "14px",
            }}
          >
            Cancel
          </button>
        </div>
      </div>
    </div>
  );
}

const EditInventoryModal = ({ item, setEditModalOpen, fetchInventory }) => {
  const [formData, setFormData] = useState({
    name: item.name || "",
    description: item.description || "",
    category: item.category || "",
    subCategories: item.subCategories.map((sub) => ({
      ...sub,
      taxType: sub.taxType || (sub.igstRate > 0 ? "igst" : "cgst_sgst"),
      // ensure location is an id rather than an object
      location:
        sub.location && typeof sub.location === "object"
          ? sub.location._id
          : sub.location || "",
      // Ensure units are in the correct structure
      units: Array.isArray(sub.units)
        ? sub.units.map((unit) => {
            const { _id, ...unitWithoutId } = unit;
            return {
              ...unitWithoutId,
              location:
                unit.location && typeof unit.location === "object"
                  ? unit.location._id
                  : unit.location,
            };
          })
        : [],
    })),
    SKU: item.SKU || "",
    images: item.images || [""],
    company:
      item.company && typeof item.company === "object"
        ? item.company._id
        : item.company || "",
    invoiceDate: item.invoiceDate || "", 
  });

  const [errors, setErrors] = useState({});
  const [companies, setCompanies] = useState([]);
  const [locations, setLocations] = useState([]);
  const inputRefs = useRef({});

  // Fetch companies and locations on mount or when item changes
  useEffect(() => {
    const fetchCompanies = async () => {
      try {
        const response = await fetch("https://api.mrmechanic.co/api/companies", {
          method: "GET",
          headers: {
            "x-auth-token": localStorage.getItem("token"),
            "Content-Type": "application/json",
          },
        });
        if (!response.ok) throw new Error("Failed to fetch companies.");
        const data = await response.json();
        setCompanies(Array.isArray(data) ? data : []);
      } catch (error) {
        console.error("Error fetching companies:", error);
      }
    };

    const fetchLocations = async () => {
      try {
        const response = await fetch("https://api.mrmechanic.co/api/location", {
          method: "GET",
          headers: {
            "x-auth-token": localStorage.getItem("token"),
            "Content-Type": "application/json",
          },
        });
        if (!response.ok) throw new Error("Failed to fetch locations.");
        const data = await response.json();
        setLocations(Array.isArray(data) ? data : []);
      } catch (error) {
        console.error("Error fetching locations:", error);
      }
    };

    fetchCompanies();
    fetchLocations();
  }, [item]);

  // Consolidated financial calculations (similar to InventoryModal)
  const calculateFinancials = (subCategory) => {
    // Ensure proper numeric values
    const quantity = parseFloat(subCategory.quantity) || 0;
    const discountPercentage = parseFloat(subCategory.discountPercentage) || 0;
    const costPrice = parseFloat(subCategory.costPrice) || 0;
    const profitMargin = parseFloat(subCategory.profitMargin) || 0;
    const igstRate = parseFloat(subCategory.igstRate) || 0;
    const cgstRate = parseFloat(subCategory.cgstRate) || 0;
    const sgstRate = parseFloat(subCategory.sgstRate) || 0;

    // Calculate price after discount
    const priceAfterDiscount = costPrice * (1 - discountPercentage / 100);
    // Apply profit margin if provided
    const price = profitMargin ? priceAfterDiscount * (1 + profitMargin / 100) : priceAfterDiscount;
    // Taxable amount
    const taxableAmount = price * quantity;

    // Calculate GST amounts (IGST takes precedence)
    let gstAmount = 0, cgstAmount = 0, sgstAmount = 0;
    if (subCategory.taxType === "igst") {
      gstAmount = (taxableAmount * igstRate) / 100;
    } else if (subCategory.taxType === "cgst_sgst") {
      cgstAmount = (taxableAmount * cgstRate) / 100;
      sgstAmount = (taxableAmount * sgstRate) / 100;
      gstAmount = cgstAmount + sgstAmount;
    }

    const totalAmount = taxableAmount + gstAmount;

    // Per unit calculations
    const taxAmountPerUnit = quantity > 0 ? gstAmount / quantity : 0;
    const finalPricePerUnit = priceAfterDiscount + taxAmountPerUnit;

    // Ensure units are generated if empty
    subCategory.units =
      subCategory.units && subCategory.units.length > 0
        ? subCategory.units
        : Array.from({ length: quantity }, () => ({
            uniqueId: uuidv4(),
            location: subCategory.location,
          }));

    return {
      price,
      taxableAmount: parseFloat(taxableAmount.toFixed(2)),
      gstAmount: parseFloat(gstAmount.toFixed(2)),
      cgstAmount: parseFloat(cgstAmount.toFixed(2)),
      sgstAmount: parseFloat(sgstAmount.toFixed(2)),
      totalAmount: parseFloat(totalAmount.toFixed(2)),
      discountedPricePerUnit: parseFloat(priceAfterDiscount.toFixed(2)),
      taxAmountPerUnit: parseFloat(taxAmountPerUnit.toFixed(2)),
      finalPricePerUnit: parseFloat(finalPricePerUnit.toFixed(2)),
      units: subCategory.units,
      unit: subCategory.type === "liquid" ? "ML" : "Unit",
    };
  };

  const handleSubCategoryChange = (index, e) => {
    const { name, value } = e.target;
    const updatedSubCategories = [...formData.subCategories];

    // Special handling for quantity changes
    if (name === "quantity") {
      const newQuantity = parseInt(value) || 0;
      const currentQuantity = updatedSubCategories[index].units?.length || 0;
      const location = updatedSubCategories[index].location || "";
      if (newQuantity > currentQuantity) {
        // Add new units
        const additionalUnits = Array.from({ length: newQuantity - currentQuantity }, () => ({
          uniqueId: uuidv4(),
          location,
        }));
        updatedSubCategories[index].units = [
          ...(updatedSubCategories[index].units || []),
          ...additionalUnits,
        ];
      } else if (newQuantity < currentQuantity) {
        // Remove excess units
        updatedSubCategories[index].units = updatedSubCategories[index].units.slice(0, newQuantity);
      }
    }

    // When location changes, update unit locations as well
    if (name === "location") {
      if (updatedSubCategories[index].units && updatedSubCategories[index].units.length > 0) {
        updatedSubCategories[index].units = updatedSubCategories[index].units.map((unit) => ({
          ...unit,
          location: value,
        }));
      }
    }

    updatedSubCategories[index][name] = value;

    // Recalculate financials when relevant fields change
    if (
      ["price", "discountPercentage", "costPrice", "profitMargin", "igstRate", "cgstRate", "sgstRate", "taxType"].includes(name)
    ) {
      const calculated = calculateFinancials(updatedSubCategories[index]);
      updatedSubCategories[index] = { ...updatedSubCategories[index], ...calculated };
    }

    setFormData({ ...formData, subCategories: updatedSubCategories });
  };

  const handleTaxTypeChange = (index, taxType) => {
    const updatedSubCategories = [...formData.subCategories];
    updatedSubCategories[index].taxType = taxType;

    // Reset the non-relevant tax rates
    if (taxType === "igst") {
      updatedSubCategories[index].cgstRate = 0;
      updatedSubCategories[index].sgstRate = 0;
    } else if (taxType === "cgst_sgst") {
      updatedSubCategories[index].igstRate = 0;
    }

    const calculated = calculateFinancials(updatedSubCategories[index]);
    updatedSubCategories[index] = { ...updatedSubCategories[index], ...calculated };

    setFormData({ ...formData, subCategories: updatedSubCategories });
  };

  const addSubCategory = () => {
    const generateUnitIds = (qty) =>
      Array.from({ length: qty }, () => ({
        uniqueId: uuidv4(),
        location: "",
      }));

    setFormData({
      ...formData,
      subCategories: [
        ...formData.subCategories,
        { name: "", quantity: 1, costPrice: 0, profitMargin: 0, price: 0, type: "solid", unit: "Unit", location: "", discountPercentage: 0, taxType: "igst", igstRate: 0, cgstRate: 0, sgstRate: 0, cgstAmount: 0, sgstAmount: 0, gstAmount: 0, taxableAmount: 0, totalAmount: 0, units: generateUnitIds(1), measurement: "unit",
        },
      ],
    });
  };

  const toggleMeasurement = (index) => {
    const updatedSubCategories = [...formData.subCategories];
    updatedSubCategories[index].measurement =
      updatedSubCategories[index].measurement === "unit" ? "mL" : "unit";
    setFormData({ ...formData, subCategories: updatedSubCategories });
  };

  const validateForm = () => {
    const newErrors = {};
    if (!formData.name) newErrors.name = "Name is required.";
    if (!formData.description) newErrors.description = "Description is required.";
    if (!formData.category) newErrors.category = "Category is required.";
    if (!formData.SKU) newErrors.SKU = "HSN Code is required.";
    if (!formData.company) newErrors.company = "Company is required.";

    formData.subCategories.forEach((subCategory, index) => {
      if (!subCategory.name)
        newErrors[`subCategoryName${index}`] = "Subcategory name is required.";
      if (!subCategory.quantity)
        newErrors[`subCategoryQuantity${index}`] = "Quantity is required.";
      if (!subCategory.price)
        newErrors[`subCategoryPrice${index}`] = "Price is required.";
      if (!subCategory.uniqueId)
        newErrors[`subCategoryUniqueId${index}`] = "Unique ID is required.";
      if (!subCategory.location)
        newErrors[`subCategoryLocation${index}`] = "Location is required.";
    });

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const scrollToError = () => {
    const firstErrorKey = Object.keys(errors)[0];
    if (firstErrorKey && inputRefs.current[firstErrorKey]) {
      inputRefs.current[firstErrorKey].scrollIntoView({ behavior: "smooth", block: "center" });
    }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = () => {
    if (!validateForm()) {
      scrollToError();
      return;
    }

    const token = localStorage.getItem("token");
    if (!token) {
      console.error("No token found. Please log in.");
      return;
    }

    let userId = null;
    const userString = localStorage.getItem("user");
    if (userString) {
      try {
        const userInfo = JSON.parse(userString);
        userId = userInfo._id;
      } catch (error) {
        console.error("Error parsing user data:", error);
      }
    }

    const dataToSend = { ...formData };
    if (userId) {
      dataToSend.updatedBy = userId;
    }

    fetch(`https://api.mrmechanic.co/api/inventory/${item._id}`, {
      method: "PUT",
      headers: {
        "x-auth-token": token,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(dataToSend),
    })
      .then((response) => {
        if (!response.ok) {
          return response.text().then(text => {
            throw new Error(`Error: ${response.status} - ${text}`);
          });
        }
        return response.json();
      })
      .then((result) => {
        console.log("Update successful:", result);
        setEditModalOpen(false);
        fetchInventory();
      })
      .catch((error) => {
        console.error("Error updating inventory:", error);
        alert("Failed to update inventory. Please check console for details.");
      });
  };

  // Inline styles (you can refactor these into CSS modules or styled components)
  const modalStyle = { display: "flex", justifyContent: "center", alignItems: "center", position: "fixed", top: 0, left: 0, right: 0, bottom: 0, backgroundColor: "rgba(0, 0, 0, 0.5)", zIndex: 1000,};
  const modalContentStyle = { backgroundColor: "#fff", padding: "20px", borderRadius: "8px", boxShadow: "0 4px 8px rgba(0, 0, 0, 0.2)", width: "90%", maxWidth: "1000px", maxHeight: "90vh", overflowY: "auto", display: "flex", flexDirection: "column", margin: "0 auto",};
  const scrollContainerStyle = { overflowY: "auto", maxHeight: "calc(90vh - 120px)" };
  const inputStyle = { width: "98%", padding: "12px", margin: "10px 0", borderRadius: "4px", border: "1px solid #ccc",};
  const labelStyle = { display: "block", margin: "5px 0", fontWeight: "bold" };
  const errorStyle = { color: "red", fontSize: "12px" };
  const toggleButtonStyle = { padding: "10px 20px", margin: "10px 0", border: "none", borderRadius: "4px", backgroundColor: "#4CAF50", color: "white", cursor: "pointer", textAlign: "center",};
  return (
    <div style={modalStyle}>
      <div style={modalContentStyle}>
        <h2>Edit Inventory</h2>
        <div style={scrollContainerStyle}>
          <form>
            <div style={{ display: "flex", gap: "40px", marginBottom: "10px" }}>
              <div style={{ flex: 1 }}>
                <label style={labelStyle}>Part Name</label>
                <input
                  type="text"
                  name="name"
                  value={formData.name}
                  ref={(el) => (inputRefs.current.name = el)}
                  onChange={handleInputChange}
                  placeholder="Enter Part Name"
                  style={inputStyle}
                />
                {errors.name && <div style={errorStyle}>{errors.name}</div>}
              </div>
              <div style={{ flex: 1 }}>
                <label style={labelStyle}>Description</label>
                <input
                  type="text"
                  name="description"
                  value={formData.description}
                  ref={(el) => (inputRefs.current.description = el)}
                  onChange={handleInputChange}
                  placeholder="Enter item description"
                  style={inputStyle}
                />
                {errors.description && <div style={errorStyle}>{errors.description}</div>}
              </div>
              <div style={{ flex: 1 }}>
                <label style={labelStyle}>Category</label>
                <input
                  type="text"
                  name="category"
                  value={formData.category}
                  ref={(el) => (inputRefs.current.category = el)}
                  onChange={handleInputChange}
                  placeholder="Enter category"
                  style={inputStyle}
                />
                {errors.category && <div style={errorStyle}>{errors.category}</div>}
              </div>
            </div>

            {/* Subcategories Section */}
            {formData.subCategories.map((subCategory, index) => (
              <div key={index} style={{ marginBottom: "20px", padding: "15px", border: "1px solid #eee", borderRadius: "5px" }}>
                <h4>Subcategory {index + 1}</h4>
                <div style={{ display: "flex", gap: "40px", marginBottom: "10px" }}>
                  <div style={{ flex: 1 }}>
                    <label style={labelStyle}>Subcategory Name</label>
                    <input
                      type="text"
                      name="name"
                      value={subCategory.name}
                      onChange={(e) => handleSubCategoryChange(index, e)}
                      placeholder="Enter subcategory name"
                      style={inputStyle}
                      ref={(el) => (inputRefs.current[`subCategoryName${index}`] = el)}
                    />
                    {errors[`subCategoryName${index}`] && <div style={errorStyle}>{errors[`subCategoryName${index}`]}</div>}
                  </div>
                  <div style={{ flex: 1 }}>
                    <label style={labelStyle}>Quantity</label>
                    <input
                      type="number"
                      name="quantity"
                      value={subCategory.quantity}
                      onChange={(e) => handleSubCategoryChange(index, e)}
                      placeholder="Enter quantity"
                      style={inputStyle}
                      ref={(el) => (inputRefs.current[`subCategoryQuantity${index}`] = el)}
                    />
                    {errors[`subCategoryQuantity${index}`] && <div style={errorStyle}>{errors[`subCategoryQuantity${index}`]}</div>}
                  </div>
                  <div style={{ flex: 1 }}>
                    <label style={labelStyle}>Cost per Item</label>
                    <input
                      type="number"
                      name="costPrice"
                      value={subCategory.costPrice}
                      onChange={(e) => handleSubCategoryChange(index, e)}
                      placeholder="Enter cost price"
                      style={inputStyle}
                    />
                  </div>
                  <div style={{ flex: 1 }}>
                    <label style={labelStyle}>Profit Margin (%)</label>
                    <input
                      type="number"
                      name="profitMargin"
                      value={subCategory.profitMargin}
                      onChange={(e) => handleSubCategoryChange(index, e)}
                      placeholder="Enter profit margin"
                      style={inputStyle}
                    />
                  </div>
                </div>

                {/* Taxation Section */}
                <div style={{ marginBottom: "15px", padding: "10px", backgroundColor: "#f9f9f9", borderRadius: "5px" }}>
                  <div style={{ marginBottom: "10px" }}>
                  <label style={labelStyle}>Tax Type</label>
                  <div style={{ display: "flex", gap: "20px", margin: "10px 0" }}>
                    <label style={{ display: "flex", alignItems: "center" }}>
                      <input
                        type="radio"
                        name={`taxType-${index}`}
                        value="igst"
                        checked={subCategory.taxType === "igst"}
                        onChange={() => handleTaxTypeChange(index, "igst")}
                        style={{ marginRight: "5px" }}
                      />
                      IGST
                    </label>
                    <label style={{ display: "flex", alignItems: "center" }}>
                      <input
                        type="radio"
                        name={`taxType-${index}`}
                        value="cgst_sgst"
                        checked={subCategory.taxType === "cgst_sgst"}
                        onChange={() => handleTaxTypeChange(index, "cgst_sgst")}
                        style={{ marginRight: "5px" }}
                      />
                      CGST + SGST
                    </label>
                    </div>
                  </div>
                  <div style={{ display: "flex", gap: "40px", marginBottom: "10px" }}>
                    <div style={{ flex: 1 }}>
                      <label style={labelStyle}>Discount Percentage</label>
                      <input
                        type="number"
                        name="discountPercentage"
                        value={subCategory.discountPercentage}
                        onChange={(e) => handleSubCategoryChange(index, e)}
                        style={inputStyle}
                      />
                    </div>
                    <div style={{ flex: 1 }}>
                      <label style={labelStyle}>Taxable Amount</label>
                      <input
                        type="number"
                        name="taxableAmount"
                        value={subCategory.taxableAmount}
                        readOnly
                        style={{ ...inputStyle, backgroundColor: "#f0f0f0" }}
                      />
                    </div>
                  </div>
                  {subCategory.taxType === "igst" ? (
                    <div style={{ display: "flex", gap: "40px", marginBottom: "10px" }}>
                      <div style={{ flex: 1 }}>
                        <label style={labelStyle}>IGST Rate (%)</label>
                        <input
                          type="number"
                          name="igstRate"
                          value={subCategory.igstRate}
                          onChange={(e) => handleSubCategoryChange(index, e)}
                          style={inputStyle}
                        />
                      </div>
                      <div style={{ flex: 1 }}>
                        <label style={labelStyle}>IGST Amount</label>
                        <input
                          type="number"
                          name="igstAmount"
                          value={subCategory.gstAmount}
                          readOnly
                          style={{ ...inputStyle, backgroundColor: "#f0f0f0" }}
                        />
                      </div>
                    </div>
                  ) : (
                    <div style={{ display: "flex", gap: "40px", marginBottom: "10px" }}>
                      <div style={{ flex: 1 }}>
                        <label style={labelStyle}>CGST Rate (%)</label>
                        <input
                          type="number"
                          name="cgstRate"
                          value={subCategory.cgstRate}
                          onChange={(e) => handleSubCategoryChange(index, e)}
                          style={inputStyle}
                        />
                      </div>
                      <div style={{ flex: 1 }}>
                        <label style={labelStyle}>CGST Amount</label>
                        <input
                          type="number"
                          name="cgstAmount"
                          value={subCategory.cgstAmount}
                          readOnly
                          style={{ ...inputStyle, backgroundColor: "#f0f0f0" }}
                        />
                      </div>
                      <div style={{ flex: 1 }}>
                        <label style={labelStyle}>SGST Rate (%)</label>
                        <input
                          type="number"
                          name="sgstRate"
                          value={subCategory.sgstRate}
                          onChange={(e) => handleSubCategoryChange(index, e)}
                          style={inputStyle}
                        />
                      </div>
                      <div style={{ flex: 1 }}>
                        <label style={labelStyle}>SGST Amount</label>
                        <input
                          type="number"
                          name="sgstAmount"
                          value={subCategory.sgstAmount}
                          readOnly
                          style={{ ...inputStyle, backgroundColor: "#f0f0f0" }}
                        />
                      </div>
                    </div>
                  )}
                  <div style={{ display: "flex", gap: "40px" }}>
                    <div style={{ flex: 1 }}>
                      <label style={labelStyle}>Total Amount</label>
                      <input
                        type="number"
                        name="totalAmount"
                        value={subCategory.totalAmount}
                        readOnly
                        style={{ ...inputStyle, backgroundColor: "#f0f0f0" }}
                      />
                    </div>
                  </div>
                </div>

                <div style={{ display: "flex", gap: "40px", marginBottom: "10px" }}>
                  <div style={{ flex: 1 }}>
                    <label style={labelStyle}>Invoice Number</label>
                    <input
                      type="text"
                      name="uniqueId"
                      value={subCategory.uniqueId || ""}
                      onChange={(e) => handleSubCategoryChange(index, e)}
                      placeholder="Enter Invoice Number"
                      style={inputStyle}
                      ref={(el) => (inputRefs.current[`subCategoryUniqueId${index}`] = el)}
                    />
                    {errors[`subCategoryUniqueId${index}`] && <div style={errorStyle}>{errors[`subCategoryUniqueId${index}`]}</div>}
                  </div>
                  <div style={{ flex: 1 }}>
                    <label style={labelStyle}>Item Type</label>
                    <select
                      name="type"
                      value={subCategory.type || "solid"}
                      onChange={(e) => handleSubCategoryChange(index, e)}
                      style={inputStyle}
                    >
                      <option value="" disabled>
                        Select type
                      </option>
                      <option value="solid">Solid</option>
                      <option value="liquid">Liquid</option>
                    </select>
                  </div>
                  <div style={{ flex: 1 }}>
                    <label style={labelStyle}>Measurement</label>
                    <div>
                      <span style={{ marginRight: "10px" }}>{subCategory.measurement || "unit"}</span>
                      <button type="button" onClick={() => toggleMeasurement(index)} style={toggleButtonStyle}>
                        Toggle
                      </button>
                    </div>
                  </div>
                </div>
                <label style={labelStyle}>Location</label>
                <select
                  name="location"
                  value={subCategory.location || ""}
                  onChange={(e) => handleSubCategoryChange(index, e)}
                  style={inputStyle}
                  ref={(el) => (inputRefs.current[`subCategoryLocation${index}`] = el)}
                >
                  <option value="">Select a location</option>
                  {locations.map((location) => (
                    <option key={location._id} value={location._id}>
                      {location.name}
                    </option>
                  ))}
                </select>
                {errors[`subCategoryLocation${index}`] && <div style={errorStyle}>{errors[`subCategoryLocation${index}`]}</div>}
              </div>
            ))}
            <button type="button" onClick={addSubCategory} style={toggleButtonStyle}>
              Add Subcategory
            </button>
            <div style={{ display: "flex", gap: "40px", marginBottom: "10px", marginTop: "20px" }}>
              <div style={{ flex: 1 }}>
                <label style={labelStyle}>HSN Code</label>
                <input
                  type="text"
                  name="SKU"
                  value={formData.SKU}
                  ref={(el) => (inputRefs.current.SKU = el)}
                  onChange={handleInputChange}
                  placeholder="Enter HSN Code"
                  style={inputStyle}
                />
                {errors.SKU && <div style={errorStyle}>{errors.SKU}</div>}
              </div>
              <div style={{ flex: 1 }}>
                <label style={labelStyle}>Image URL</label>
                <input
                  type="text"
                  name="images"
                  value={formData.images[0] || ""}
                  onChange={(e) => {
                    const newImages = [...formData.images];
                    newImages[0] = e.target.value;
                    setFormData({ ...formData, images: newImages });
                  }}
                  style={inputStyle}
                />
              </div>
              <div style={{ flex: 1 }}>
                <label style={labelStyle}>Company</label>
                <select
                  name="company"
                  value={formData.company || ""}
                  ref={(el) => (inputRefs.current.company = el)}
                  onChange={handleInputChange}
                  style={inputStyle}
                >
                  <option value="">Select a company</option>
                  {companies.map((company) => (
                    <option key={company._id} value={company._id}>
                      {company.name}
                    </option>
                  ))}
                </select>
                {errors.company && <div style={errorStyle}>{errors.company}</div>}
              </div>
              <div style={{ flex: 1 }}>
                <label style={labelStyle}>Invoice Date</label>
                <input
                  type="date"
                  name="invoiceDate"
                  value={formData.invoiceDate}
                  onChange={handleInputChange}
                  style={inputStyle}
                />
              </div>
            </div>
          </form>
        </div>
        <div style={{ marginTop: "20px", display: "flex", justifyContent: "space-between" }}>
          <button type="button" onClick={handleSubmit} style={toggleButtonStyle}>
            Save Changes
          </button>
          <button
            type="button"
            onClick={() => setEditModalOpen(false)}
            style={{ ...toggleButtonStyle, backgroundColor: "#DC3545" }}
          >
            Cancel
          </button>
        </div>
      </div>
    </div>
  );
};

// Table for Inventory
function Inventory() {
  const navigate = useNavigate();
  const { user } = useContext(AuthContext);
  const [inventoryList, setInventoryList] = useState([]);
  const [loading, setLoading] = useState(true); 
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [isTransferModalOpen, setIsTransferModalOpen] = useState(false);
  const [selectedSubCategory, setSelectedSubCategory] = useState(null);
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [error, setError] = useState(null);
  const [searchTerm, setSearchTerm] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage] = useState(20);
  const [companies, setCompanies] = useState([]);
  const [locations, setLocations] = useState([]);
  const [selectedItem, setSelectedItem] = useState(null);

  const openEditModal = (item) => {
    setSelectedItem(item); 
    setIsEditModalOpen(true);
  };

  // Fetch the inventory list (GET request)
  const fetchInventory = () => {
    setLoading(true);
    const token = localStorage.getItem("token");
    if (!token) {
      setError("No token found. Please log in.");
      setLoading(false);
      return;
    }
  
    const myHeaders = new Headers();
    myHeaders.append("x-auth-token", token);
  
    const requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };
  
  fetch("https://api.mrmechanic.co/api/inventory", requestOptions)
  .then((response) => response.json())
  .then((result) => {
    if (!Array.isArray(result)) {
      throw new Error("Unexpected API response format");
    }
    const sortedInventory = result.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt));
    setInventoryList(sortedInventory);
    setError(null);
    setLoading(false);
  })
  .catch((error) => {
    setError("Error fetching inventory.");
    setLoading(false);
  });
  };  

  //   Add inventory api call
  const handleFormSubmit = (data) => {
    const myHeaders = new Headers();
    myHeaders.append("x-auth-token", localStorage.getItem("token"));
    myHeaders.append("Content-Type", "application/json");

    const requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: JSON.stringify(data), // Use form data collected from the modal
      redirect: "follow",
    };

    fetch("https://api.mrmechanic.co/api/inventory", requestOptions)
      .then((response) => response.json())
      .then((result) => {
        console.log(result);
        setIsModalOpen(false); // Close the modal after successful submission
        fetchInventory(); // Silently refresh the table
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };

  useEffect(() => {
    fetchInventory();
  }, []);

  useEffect(() => {
    // Fetch companies and locations when the component mounts
    const fetchCompaniesAndLocations = async () => {
      const headers = new Headers({
        "x-auth-token": localStorage.getItem("token"),
        "Content-Type": "application/json",
      });
  
      try {
        // Fetch companies
        const companyResponse = await fetch("https://api.mrmechanic.co/api/companies", {
          method: "GET",
          headers,
        });
  
        if (!companyResponse.ok) {
          throw new Error("Failed to fetch companies.");
        }
        const companiesData = await companyResponse.json();
        setCompanies(companiesData); 
        // Fetch locations
        const locationResponse = await fetch("https://api.mrmechanic.co/api/location", {
          method: "GET",
          headers,
        });
        if (!locationResponse.ok) {
          throw new Error("Failed to fetch locations.");
        }
        const locationsData = await locationResponse.json();
        setLocations(locationsData); 
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };
  
    fetchCompaniesAndLocations();
  }, []);
  
  const getCompanyName = (company) => {
    if (company && typeof company === "object") {
      return company.name;
    }
    const foundCompany = companies.find((comp) => comp._id === company);
    return foundCompany ? foundCompany.name : "Unknown";
  };
  
  const getLocationName = (location) => {
    if (location && typeof location === "object") {
      return location.name;
    }
    const foundLocation = locations.find((loc) => loc._id === location);
    return foundLocation ? foundLocation.name : "Unknown";
  };  

  //   Stock transfer api call
  const handleTransferSubmit = (data) => {
    const myHeaders = new Headers();
    myHeaders.append("x-auth-token", localStorage.getItem("token"));
    myHeaders.append("Content-Type", "application/json");

    const transferQuantity = Number(data.transferQuantity);

    if (isNaN(transferQuantity) || transferQuantity <= 0) {
      alert("Please enter a valid transfer quantity greater than 0.");
      return;
    }

    // Ensure uniqueIds matches the transferQuantity
    const uniqueIds = selectedSubCategory.units
      .slice(0, transferQuantity)
      .map((unit) => unit.uniqueId);

    const requestOptions = {
      method: "PUT",
      headers: myHeaders,
      body: JSON.stringify({
        subCategoryName: data.subCategoryName,
        newLocation: data.newLocation,
        transferQuantity: transferQuantity,
        uniqueIds, 
      }),
      redirect: "follow",
    };

    fetch(
      `https://api.mrmechanic.co/api/inventory/transfer/${selectedSubCategory.parentId}`,
      requestOptions
    )
      .then((response) => {
        if (!response.ok) {
          return response.json().then((error) => {
            throw new Error(error.message);
          });
        }
        return response.json();
      })
      .then((result) => {
        setIsTransferModalOpen(false);
        fetchInventory();
      })
      .catch((error) => {
        console.error("Error:", error.message);
      });
  };

  // Filter inventory based on search term
  const filteredInventory = inventoryList.filter((item) => {
    const name = item.name || "";
    const companyName = getCompanyName(item.company); // Use helper to get the company name
  
    // Convert both fields and searchTerm to lower case
    const lowerName = name.toLowerCase();
    const lowerCompany = companyName.toLowerCase();
    const lowerSearchTerm = searchTerm.toLowerCase();
  
    // Check if either field includes the search term
    return lowerName.includes(lowerSearchTerm) || lowerCompany.includes(lowerSearchTerm);
  });  

  // Pagination logic
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = filteredInventory.slice(
    indexOfFirstItem,
    indexOfLastItem
  );
  const totalPages = Math.ceil(filteredInventory.length / itemsPerPage);
  const dashboardStyle = {display: "flex",flexDirection: "row",height: "100vh", };
  const navbarStyle = {width: "100%", position: "fixed", borderRight: "1px solid #ddd",}
  const sidebarStyle = {width: "20%", position: "fixed",height: "100vh", borderRight: "1px solid #ddd",marginTop: "60px",};
  const contentStyle = {marginTop: "2%",marginLeft: "20%", padding: "20px",flex: 1,height: "calc(100vh - 60px)", };
  const tableStyle = {width: "100%",borderCollapse: "collapse",marginBottom: "20px",};
  const thStyle = {backgroundColor: "#4CAF50",color: "white",padding: "10px",textAlign: "left",};
  const tdStyle = {padding: "10px",border: "1px solid #ddd",};
  return (
    <div>
      <div style={navbarStyle}>
        <Navbar />
      </div>
      <div style={dashboardStyle}>
        <div style={sidebarStyle}>
          <Sidebar />
        </div>
        <div style={contentStyle}>
          <h1>Inventory</h1>
          <div
            style={{ display: "flex", justifyContent: "space-between", alignItems: "center", marginBottom: "20px",
            }}
          >
            <input
              type="text"
              placeholder="Search by name, company"
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
              style={{ padding: "10px", width: "300px", borderRadius: "4px", border: "1px solid #ccc", boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
              }}
            />
            {user && user.role === "superadmin" && (
              <button
                onClick={() => setIsModalOpen(true)}
                style={{padding: "10px 20px",marginLeft: "20px",backgroundColor: "#4CAF50",color: "white",border: "none",borderRadius: "4px",cursor: "pointer",boxShadow: "0 2px 4px rgba(0, 0, 0, 0.2)",transition: "background-color 0.3s",
                }}
                onMouseOver={(e) =>
                  (e.currentTarget.style.backgroundColor = "#45a049")
                } // Darker green on hover
                onMouseOut={(e) =>
                  (e.currentTarget.style.backgroundColor = "#4CAF50")
                } // Reset color
              >
                Add Inventory
              </button>
            )}
          </div>
          {error && <p style={{ color: "red" }}>{error}</p>}
          <div>
            {loading ? (
              <p>Inventories Loading...</p>  // Show loading text
            ) : (
            <div>
              <table style={tableStyle}>
              <thead>
              <tr>
                <th style={thStyle} rowSpan="2">Part Name</th>
                <th style={thStyle} rowSpan="2">Category</th>
                <th style={thStyle} rowSpan="2">Item Name</th>
                <th style={thStyle} rowSpan="2">Invoice Date</th>
                <th style={thStyle} rowSpan="2">Item Location</th>
                <th style={thStyle} rowSpan="2">Item Quantity</th>
                <th style={thStyle} rowSpan="2">Rate</th>
                <th style={thStyle} rowSpan="2">Discount %</th>
                <th style={thStyle} rowSpan="2">Price/Unit</th>
                <th style={thStyle} rowSpan="2">Profit Margin %</th>               
                <th style={thStyle} rowSpan="2">Item Price</th>
                <th style={thStyle} rowSpan="2">Total Taxable Amount</th>
                
                {/* Tax Group Headers */}
                <th style={{ ...thStyle, textAlign: "center" }} colSpan="2">IGST</th>
                <th style={{ ...thStyle, textAlign: "center" }} colSpan="2">CGST</th>
                <th style={{ ...thStyle, textAlign: "center" }} colSpan="2">SGST</th>
                <th style={thStyle} rowSpan="2">Total GST</th>
                <th style={thStyle} rowSpan="2">Final Price</th>
                {/* <th style={thStyle} rowSpan="2">Item Type</th>
                <th style={thStyle} rowSpan="2">HSN Code</th> */}
                <th style={thStyle} rowSpan="2">Company</th>
                <th style={thStyle} rowSpan="2">Actions</th>               
              </tr>
              <tr>
                {/* Tax Sub-Headers */}
                <th style={thStyle}>Rate%</th>
                <th style={thStyle}>Amount</th>
                <th style={thStyle}>Rate%</th>
                <th style={thStyle}>Amount</th>
                <th style={thStyle}>Rate%</th>
                <th style={thStyle}>Amount</th>
              </tr>
            </thead>
            <tbody>
              {currentItems.map((item) =>
                item.subCategories.map((subCategory) => (
                  <tr key={subCategory._id}>
                    {/* Existing columns */}
                    {subCategory === item.subCategories[0] ? (
                      <td rowSpan={item.subCategories.length} style={tdStyle}>
                        {item.name}
                      </td>
                    ) : null}
                      {/* {subCategory === item.subCategories[0] ? (
                        <td rowSpan={item.subCategories.length} style={tdStyle}>
                          {item.description}
                        </td>
                      ) : null} */}
                     {subCategory === item.subCategories[0] ? (
                      <td rowSpan={item.subCategories.length} style={tdStyle}>
                        {typeof item.category === 'object' ? item.category.name : item.category}
                      </td>
                    ) : null}

                      <td style={tdStyle}>{subCategory.name}</td>
                      <td style={tdStyle}>
                        {item.invoiceDate ? new Date(item.invoiceDate).toLocaleDateString() : 'N/A'}
                      </td>
                      <td style={tdStyle}>{getLocationName(subCategory.location)}</td>
                      <td style={tdStyle}>{subCategory.quantity}</td>
                      <td style={tdStyle}>{subCategory.costPrice}</td>
                      <td style={tdStyle}>{subCategory.discountPercentage}</td>
                      <td style={tdStyle}>{subCategory.discountedPricePerUnit?.toFixed(1) || 'N/A'}</td>
                      <td style={tdStyle}>{subCategory.profitMargin?.toFixed(1) || 'N/A'}</td>                      
                      <td style={tdStyle}>
                        {subCategory.price?.toFixed(1) || 'N/A'}
                      </td>                     
                      <td style={tdStyle}>{subCategory.taxableAmount?.toFixed(1) || 'N/A'}</td>
                      {/* Tax Columns */}
                      <td style={tdStyle}>{subCategory.igstRate}</td>
                      <td style={tdStyle}>
                      {(subCategory.sgstAmount || subCategory.cgstAmount) ? 0 
                      : subCategory.totalTaxAmount?.toFixed(1) || 'N/A'}</td>
                      <td style={tdStyle}>{subCategory.cgstRate}</td>
                      <td style={tdStyle}>{subCategory.cgstAmount?.toFixed(1) || 'N/A'}</td>
                      <td style={tdStyle}>{subCategory.sgstRate}</td>
                      <td style={tdStyle}>{subCategory.sgstAmount?.toFixed(1) || 'N/A'}</td>
                      <td style={tdStyle}>{subCategory.gstAmount?.toFixed(1) || 'N/A'}</td>
                      <td style={tdStyle}>{subCategory.finalPrice?.toFixed(1) || 'N/A'}</td>

                      
                      {/* <td style={tdStyle}>{subCategory.type}</td> */}
                      {/* <td style={tdStyle}>{subCategory.unit}</td> */}
                      {/* {subCategory === item.subCategories[0] ? (
                        <td rowSpan={item.subCategories.length} style={tdStyle}>
                          {item.SKU}
                        </td>
                      ) : null} */}
                      {/* {subCategory === item.subCategories[0] ? (
                      <td rowSpan={item.subCategories.length} style={tdStyle}>
                        {item.images}
                      </td>
                    ) : null} */}
                     {subCategory === item.subCategories[0] ? (
                      <td rowSpan={item.subCategories.length} style={tdStyle}>
                        {getCompanyName(item.company)} {/* Dynamically fetch the company name */}
                      </td>
                    ) : null}
                      {subCategory === item.subCategories[0] ? (
                        <td
                          style={{
                            display: "flex",
                            alignItems: "center",
                          }}
                        >
                          <button
                            style={{ padding: "5px 10px", backgroundColor: "#007BFF", color: "white", border: "none", borderRadius: "4px", cursor: "pointer", marginRight: "10px", display: "inline-block", verticalAlign: "middle",
                            }}
                            onClick={() => openEditModal(item)} // Open the modal with current data
                          >
                            Edit
                          </button>

                         {/* {user && user.role === "superadmin" && (
                            <button
                              style={{
                                padding: "5px 10px",
                                backgroundColor: "red",
                                color: "white",
                                border: "none",
                                borderRadius: "4px",
                                cursor: "pointer",
                                display: "inline-block",
                                verticalAlign: "middle",
                              }}
                              onClick={() => {
                                if (
                                  window.confirm(
                                    "Are you sure you want to delete this inventory?"
                                  )
                                ) {
                                  const token = localStorage.getItem("token");

                                  if (!token) {
                                    console.error(
                                      "No token found. Please log in."
                                    );
                                    return;
                                  }

                                  const myHeaders = new Headers();
                                  myHeaders.append("x-auth-token", token);

                                  const requestOptions = {
                                    method: "DELETE",
                                    headers: myHeaders,
                                    redirect: "follow",
                                  };

                                  fetch(
                                    `https://api.mrmechanic.co/api/inventory/${item._id}`,
                                    requestOptions
                                  )
                                    .then((response) => {
                                      if (!response.ok) {
                                        throw new Error(
                                          `Error: ${response.status} ${response.statusText}`
                                        );
                                      }
                                      return response.text();
                                    })
                                    .then((result) => {
                                      console.log(result);
                                      // Optionally, refresh the inventory list after successful deletion
                                      fetchInventory();
                                    })
                                    .catch((error) =>
                                      console.error("Error:", error)
                                    );
                                }
                              }}
                            >
                              Delete
                            </button>
                          )}  */}

                          {/* Stock transfer */}
                          {/* <button
                            style={{ padding: "5px 10px", backgroundColor: "gray", color: "white", border: "none", borderRadius: "4px", cursor: "pointer", marginLeft: "10px"}}
                            onClick={() => {
                              const subCategoryWithQuantity = {
                                ...subCategory, // This assumes subCategory has an object structure that you need
                                parentId: item._id, // Store the parent category's _id
                                availableQuantity: subCategory.quantity, // Ensure you have the available quantity
                              };

                              setSelectedSubCategory(subCategoryWithQuantity); // Set the selected subcategory
                              setSelectedCategory(item); // Set the selected category here if needed
                              setIsTransferModalOpen(true); // Open the transfer modal
                            }}
                          >
                            Transfer
                          </button> */}
                          <button
                          style={{ padding: "5px 10px", backgroundColor: "gray", color: "white", border: "none", borderRadius: "4px", cursor: "pointer", marginLeft: "10px"}}                         
                          onClick={() => navigate(`/inventoryItemDetails?id=${item._id}`)}
                        >
                          See details
                        </button>
                        </td>
                      ) : null}
                    </tr>
                  ))
                )}
              </tbody>
            </table>
            </div>
            )}
            {error && <p style={{ color: "red" }}>{error}</p>}
          </div>

          <div style={{ marginTop: "20px", display: "flex", alignItems: "center", gap: "10px" }}>
            <span style={{ fontSize: "16px", fontWeight: "500", color: "#333" }}>
              Page {currentPage} of {totalPages}
            </span>
            <button
              disabled={currentPage === 1}
              onClick={() => setCurrentPage(currentPage - 1)}
              style={{
                padding: "10px 20px",
                backgroundColor: currentPage === 1 ? "#ccc" : "#007BFF",
                color: "white",
                border: "none",
                borderRadius: "4px",
                cursor: currentPage === 1 ? "not-allowed" : "pointer",
                transition: "background-color 0.3s, transform 0.2s",
                boxShadow: "0 2px 4px rgba(0, 0, 0, 0.2)",
              }}
              onMouseOver={(e) => {
                if (currentPage !== 1) e.currentTarget.style.backgroundColor = "#0056b3";
              }}
              onMouseOut={(e) => {
                if (currentPage !== 1) e.currentTarget.style.backgroundColor = "#007BFF";
              }}
              onMouseDown={(e) => {
                if (currentPage !== 1) e.currentTarget.style.transform = "scale(0.95)";
              }}
              onMouseUp={(e) => {
                if (currentPage !== 1) e.currentTarget.style.transform = "scale(1)";
              }}
            >
              Previous
            </button>
            <button
              disabled={currentPage === totalPages}
              onClick={() => setCurrentPage(currentPage + 1)}
              style={{
                padding: "10px 20px",
                backgroundColor: currentPage === totalPages ? "#ccc" : "#007BFF",
                color: "white",
                border: "none",
                borderRadius: "4px",
                cursor: currentPage === totalPages ? "not-allowed" : "pointer",
                transition: "background-color 0.3s, transform 0.2s",
                boxShadow: "0 2px 4px rgba(0, 0, 0, 0.2)",
              }}
              onMouseOver={(e) => {
                if (currentPage !== totalPages) e.currentTarget.style.backgroundColor = "#0056b3";
              }}
              onMouseOut={(e) => {
                if (currentPage !== totalPages) e.currentTarget.style.backgroundColor = "#007BFF";
              }}
              onMouseDown={(e) => {
                if (currentPage !== totalPages) e.currentTarget.style.transform = "scale(0.95)";
              }}
              onMouseUp={(e) => {
                if (currentPage !== totalPages) e.currentTarget.style.transform = "scale(1)";
              }}
            >
              Next
            </button>
          </div>

          {/* add Inventory modal */}
          <InventoryModal
            isOpen={isModalOpen}
            onClose={() => setIsModalOpen(false)}
            onSubmit={handleFormSubmit}
          />
          {isEditModalOpen && (
            <EditInventoryModal
              item={selectedItem}
              setEditModalOpen={setIsEditModalOpen} 
              fetchInventory={fetchInventory}
            />
          )}

          {/* Stock transfer modal */}
          {isTransferModalOpen && (
            <TransferModal
              isOpen={isTransferModalOpen}
              onClose={() => setIsTransferModalOpen(false)}
              subCategories={selectedCategory?.subCategories || []}
              selectedSubCategory={selectedSubCategory}
              setSelectedSubCategory={setSelectedSubCategory} 
              onSubmit={handleTransferSubmit}
            />
          )}
        </div>
      </div>
    </div>
  );
}

export default Inventory;
