import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useParams, useNavigate } from "react-router-dom";
import Navbar from "../../components/Navbar";
import Sidebar from "../../components/Sidebar";
import jsPDF from "jspdf";
import "jspdf-autotable";
import QRCode from "qrcode";
import "./JobCardAddInventory.css";

function JobCardAddInventory() {
  const { jobCardId } = useParams();
  const navigate = useNavigate();
  const authToken = localStorage.getItem("token");

  // Inventory related states
  const [jobCardInventory, setJobCardInventory] = useState([]);
  const [allInventory, setAllInventory] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState('');

  // Inventory modals and form data
  const [showAddModal, setShowAddModal] = useState(false);
  const [showEditModal, setShowEditModal] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null);
  const [formData, setFormData] = useState({ 
    inventoryId: '', 
    subCategoryName: '', 
    uniqueId: '', 
    quantity: 1, 
    taxType: 'sgst_cgst' 
  });
  const [selectedInventory, setSelectedInventory] = useState(null);
  const [selectedSubCategory, setSelectedSubCategory] = useState(null);
  const [editQuantity, setEditQuantity] = useState(1);

  // Labour related states (separate from inventory)
  const [labourList, setLabourList] = useState([]);
  const [showLabourModal, setShowLabourModal] = useState(false);
  const [showEditLabourModal, setShowEditLabourModal] = useState(false);
  const [labourFormData, setLabourFormData] = useState({
    serialNumber: '',
    labourName: '',
    labourUniqueId: '',
    costOfLabour: '',
    discount: '0',
    sgstRate: '', 
    cgstRate: ''
  });
  const [selectedLabour, setSelectedLabour] = useState(null);
  const [taxType, setTaxType] = useState('sgst_cgst');

  // Compute inventory groups & totals
  const inventoryGroups = {};
  let inventorySubtotal = 0;
  let totalTaxAmount = 0;
  
  jobCardInventory.forEach(item => {
    const invDetail = allInventory.find(inv => inv._id === (item.inventory?._id || item.inventory));
    let taxRate = 0;
    if (invDetail && invDetail.subCategories) {
      const subCat = invDetail.subCategories.find(sub => sub.name === item.subCategoryName);
      taxRate = subCat ? (subCat.igstRate || 0) : 0;
    }
    
    const itemTotal = item.price * item.quantity;
    inventorySubtotal += itemTotal;
    
    // Use tax type to determine how to group the taxes
    if (taxType === 'igst') {
      // For IGST, use the full rate
      if (!inventoryGroups[taxRate]) {
        inventoryGroups[taxRate] = 0;
      }
      inventoryGroups[taxRate] += itemTotal;
      totalTaxAmount += itemTotal * (taxRate / 100);
    } else {
      // For SGST+CGST, split the rate
      const splitRate = taxRate / 2;
      const key = `${splitRate}_${splitRate}`; // e.g., "9_9" for 18% tax
      if (!inventoryGroups[key]) {
        inventoryGroups[key] = 0;
      }
      inventoryGroups[key] += itemTotal;
      totalTaxAmount += itemTotal * (taxRate / 100);
    }
  });

  // Compute labour groups & totals
  const labourGroups = {};
  let labourSubtotal = 0;
  let labourTaxAmount = 0;
  
  labourList.forEach(labour => {
    const sgst = labour.sgstRate || 0;
    const cgst = labour.cgstRate || 0;
    const amount = Number(labour.finalAmount) || 0;
    const netAmount = Number(labour.netAmount) || 0;
    
    labourSubtotal += amount;
    labourTaxAmount += amount - netAmount;
    
    const taxKey = `${sgst}_${cgst}`;
    if (!labourGroups[taxKey]) {
      labourGroups[taxKey] = 0;
    }
    labourGroups[taxKey] += amount;
  });

  const grandTotal = inventorySubtotal + labourSubtotal;

  // Toggle tax type function
  const handleTaxTypeToggle = (newTaxType) => {
    setTaxType(newTaxType);
  };

  // Fetch inventory items for the job card
  const fetchJobCardInventory = async () => {
    try {
      const response = await axios.get(`https://api.mrmechanic.co/api/serviceModule/jobcard/${jobCardId}/inventory`, {
        headers: { "x-auth-token": authToken }
      });
      setJobCardInventory(response.data);
      setLoading(false);
    } catch (err) {
      console.error(err);
      setError('Failed to fetch job card inventory items');
      setLoading(false);
    }
  };

  // Fetch all available inventory items
  const fetchAllInventory = async () => {
    try {
      const response = await axios.get("https://api.mrmechanic.co/api/inventory", {
        headers: { "x-auth-token": authToken }
      });
      setAllInventory(response.data);
    } catch (err) {
      console.error(err);
    }
  };

  // Fetch labour cost entries for this job card
  const fetchLabourList = async () => {
    try {
      const response = await axios.get(`https://api.mrmechanic.co/api/serviceModule/jobcard/${jobCardId}/labour`, {
        headers: { "x-auth-token": authToken }
      });
      setLabourList(response.data);
    } catch (err) {
      console.error(err);
      setError('Failed to fetch labour cost entries');
    }
  };

  useEffect(() => {
    if (jobCardId) {
      fetchJobCardInventory();
      fetchAllInventory();
      fetchLabourList();
    }
  }, [jobCardId]);

  // Handlers for inventory inputs
  const handleInputChange = e => {
    const { name, value } = e.target;
    setFormData(prev => ({ ...prev, [name]: value }));
    
    if (name === 'taxType') {
      setTaxType(value);
    }
  };

  const handleLabourInputChange = e => {
    const { name, value } = e.target;
    setLabourFormData(prev => ({ ...prev, [name]: value }));
  };

  const handleInventorySelect = e => {
    const selectedId = e.target.value;
    // Update inventoryId while preserving uniqueId and subCategoryName.
    setFormData(prev => ({ ...prev, inventoryId: selectedId }));
    const inv = allInventory.find(item => item._id === selectedId);
    setSelectedInventory(inv || null);
    // Optionally update subCategoryName if the new inventory doesn't include the previously selected one.
    // For now, we leave subCategoryName and uniqueId intact.
  };
  
  const handleSubCategorySelect = e => {
    const subName = e.target.value;
    // Update subCategoryName without clearing the uniqueId.
    setFormData(prev => ({ ...prev, subCategoryName: subName }));
    if (selectedInventory) {
      const subCat = selectedInventory.subCategories.find(sub => sub.name === subName);
      setSelectedSubCategory(subCat || null);
    }
  };  

  const handleUnitSelect = e => {
    const unitId = e.target.value;
    setFormData(prev => ({ ...prev, uniqueId: unitId }));
  };

  const handleQuantitySelect = e => {
    setFormData(prev => ({ ...prev, quantity: Number(e.target.value) }));
  };

  const handleAddInventory = async e => {
    e.preventDefault();
    try {
      const formPayload = { ...formData };
      delete formPayload.taxType; // Remove taxType from payload to API
      
      await axios.post(`https://api.mrmechanic.co/api/serviceModule/jobcard/${jobCardId}/inventory`, formPayload, {
        headers: { "x-auth-token": authToken, "Content-Type": "application/json" }
      });
      await fetchJobCardInventory();
      setShowAddModal(false);
      setFormData({ 
        inventoryId: '', 
        subCategoryName: '', 
        uniqueId: '', 
        quantity: 1,
        taxType: taxType 
      });
      setSelectedInventory(null);
      setSelectedSubCategory(null);
    } catch (err) {
      console.error(err);
      setError('Failed to add inventory item');
    }
  };

  const handleUpdateQuantity = async () => {
    try {
      await axios.put(`https://api.mrmechanic.co/api/serviceModule/jobcard/${jobCardId}/inventory/${selectedItem._id}`, 
        { quantity: Number(editQuantity) }, 
        { headers: { "x-auth-token": authToken, "Content-Type": "application/json" } }
      );
      await fetchJobCardInventory();
      setShowEditModal(false);
    } catch (err) {
      console.error(err);
      setError('Failed to update quantity');
    }
  };

  const handleDeleteInventory = async inventoryItemId => {
    try {
      await axios.delete(`https://api.mrmechanic.co/api/serviceModule/jobcard/${jobCardId}/inventory/${inventoryItemId}`, {
        headers: { "x-auth-token": authToken }
      });
      await fetchJobCardInventory();
    } catch (err) {
      console.error(err);
      setError('Failed to delete item');
    }
  };

  // Handlers for labour form inputs
  const handleAddLabour = async e => {
    e.preventDefault();
    try {
      const cost = Number(labourFormData.costOfLabour);
      const discount = Number(labourFormData.discount) || 0;
      const sgst = Number(labourFormData.sgstRate);
      const cgst = Number(labourFormData.cgstRate);

      // Calculate amounts
      const netAmount = cost - (cost * discount / 100);
      const sgstAmount = netAmount * (sgst / 100);
      const cgstAmount = netAmount * (cgst / 100);
      const finalAmount = netAmount + sgstAmount + cgstAmount;

      const payload = {
        ...labourFormData,
        costOfLabour: cost,
        discount,
        sgstRate: sgst,
        cgstRate: cgst,
        netAmount,
        taxAmount: sgstAmount + cgstAmount,
        finalAmount
      };

      await axios.post(`https://api.mrmechanic.co/api/serviceModule/jobcard/${jobCardId}/labour`, payload, {
        headers: { "x-auth-token": authToken, "Content-Type": "application/json" }
      });
      
      await fetchLabourList();
      setShowLabourModal(false);
      setLabourFormData({
        serialNumber: '',
        labourName: '',
        labourUniqueId: '',
        costOfLabour: '',
        discount: '0',
        sgstRate: '',
        cgstRate: ''
      });
    } catch (err) {
      console.error(err);
      setError('Failed to add labour cost');
    }
  };

  const handleEditLabour = async e => {
    e.preventDefault();
    try {
      const cost = Number(labourFormData.costOfLabour);
      const discount = Number(labourFormData.discount) || 0;
      const sgst = Number(labourFormData.sgstRate);
      const cgst = Number(labourFormData.cgstRate);

      // Calculate amounts
      const netAmount = cost - (cost * discount / 100);
      const sgstAmount = netAmount * (sgst / 100);
      const cgstAmount = netAmount * (cgst / 100);
      const finalAmount = netAmount + sgstAmount + cgstAmount;

      const payload = {
        ...labourFormData,
        costOfLabour: cost,
        discount,
        sgstRate: sgst,
        cgstRate: cgst,
        netAmount,
        taxAmount: sgstAmount + cgstAmount,
        finalAmount
      };

      await axios.put(
        `https://api.mrmechanic.co/api/serviceModule/jobcard/${jobCardId}/labour/${selectedLabour._id}`,
        payload,
        { headers: { "x-auth-token": authToken, "Content-Type": "application/json" } }
      );
      
      await fetchLabourList();
      setShowEditLabourModal(false);
      setLabourFormData({
        serialNumber: '',
        labourName: '',
        labourUniqueId: '',
        costOfLabour: '',
        discount: '0',
        sgstRate: '',
        cgstRate: ''
      });
      setSelectedLabour(null);
    } catch (err) {
      console.error(err);
      setError('Failed to update labour cost');
    }
  };

  const handleDeleteLabour = async (labourId) => {
    try {
      await axios.delete(`https://api.mrmechanic.co/api/serviceModule/jobcard/${jobCardId}/labour/${labourId}`, {
        headers: { "x-auth-token": authToken }
      });
      await fetchLabourList();
    } catch (err) {
      console.error(err);
      setError('Failed to delete labour cost');
    }
  };

  // Generate invoice PDF with QR code
  const handleGenerateInvoice = async () => {
    const qrDataUrl = await QRCode.toDataURL(`https://mrmechanic.co/invoice?jobCardId=${jobCardId}`, { errorCorrectionLevel: 'H' });
    
    const doc = new jsPDF({ unit: 'mm', format: 'a4' });
    doc.setFont("helvetica", "normal");
    doc.setFontSize(16);
    doc.text("Invoice", 14, 15);
    doc.addImage(qrDataUrl, "PNG", 150, 10, 40, 40);

    // For inventory items in the PDF
    const inventoryColumns = taxType === 'igst' 
      ? ["Item Name", "SKU", "Sub Category", "Unique ID", "Qty", "Price", "IGST", "Total"]
      : ["Item Name", "SKU", "Sub Category", "Unique ID", "Qty", "Price", "CGST", "SGST", "Total"];

    const inventoryRows = jobCardInventory.map(item => {
      const invDetail = allInventory.find(inv => inv._id === (item.inventory?._id || item.inventory));
      let sku = "";
      let subCatName = item.subCategoryName;
      let taxRate = 0;
      let uniqueIdDisplay = item.uniqueId;

      if (invDetail && invDetail.subCategories) {
        const subCat = invDetail.subCategories.find(sub => sub.name === item.subCategoryName);
        taxRate = subCat ? (subCat.igstRate || 0) : 0;
        sku = invDetail.SKU || "";
        
        if (subCat?.units) {
          const unit = subCat.units.find(u => u.uniqueId === item.uniqueId);
          uniqueIdDisplay = unit && unit.partId ? `${unit.partId} - ${item.uniqueId}` : item.uniqueId;
        }
      }
      
      const baseRow = [
        item.inventory?.name,
        sku,
        subCatName,
        uniqueIdDisplay,
        item.quantity,
        `₹${item.price}`,
      ];
      
      if (taxType === 'igst') {
        return [
          ...baseRow,
          `${taxRate}%`,
          `₹${(item.price * item.quantity).toFixed(2)}`
        ];
      } else {
        const splitRate = taxRate / 2;
        return [
          ...baseRow,
          `${splitRate}%`,
          `${splitRate}%`,
          `₹${(item.price * item.quantity).toFixed(2)}`
        ];
      }
    });
    
    doc.autoTable({
      head: [inventoryColumns],
      body: inventoryRows,
      startY: 50,
      theme: 'striped',
      headStyles: { fillColor: [0, 123, 255], textColor: 255, fontSize: 9, halign: 'center' },
      bodyStyles: { fontSize: 8, halign: 'center' },
      columnStyles: {
        4: { halign: 'center' },
        5: { halign: 'right' },
        6: { halign: 'center' },
        7: { halign: 'right' }
      },
      margin: { top: 50, left: 14, right: 14 }
    });

    let finalY = doc.lastAutoTable.finalY + 10;
    doc.setFontSize(12);
    doc.text("Inventory Totals Summary", 14, finalY);
    finalY += 6;
    Object.keys(inventoryGroups).forEach(rate => {
      doc.text(`${rate}%: ₹${inventoryGroups[rate].toFixed(2)}`, 14, finalY);
      finalY += 6;
    });
    doc.text(`Inventory Subtotal: ₹${inventorySubtotal.toFixed(2)}`, 14, finalY);

    finalY += 10;
    doc.setFontSize(12);
    doc.text("Labour Costs", 14, finalY);
    finalY += 6;
    const labourColumns = ["S.No", "Labour Name", "Unique ID", "Cost", "Discount %", "SGST %", "CGST %", "Final Amt"];
    const labourRows = labourList.map(labour => [
      labour.serialNumber,
      labour.labourName,
      labour.labourUniqueId,
      `₹${labour.costOfLabour}`,
      `${labour.discount}%`,
      `${labour.sgstRate}%`,
      `${labour.cgstRate}%`,
      `₹${labour.finalAmount}`
    ]);
    doc.autoTable({
      head: [labourColumns],
      body: labourRows,
      startY: finalY,
      theme: 'striped',
      headStyles: { fillColor: [0, 123, 255], textColor: 255, fontSize: 9, halign: 'center' },
      bodyStyles: { fontSize: 8, halign: 'center' },
      columnStyles: {
        3: { halign: 'right' },
        4: { halign: 'right' },
        5: { halign: 'center' },
        6: { halign: 'right' }
      },
      margin: { top: 20, left: 14, right: 14 }
    });

    finalY = doc.lastAutoTable.finalY + 10;
    doc.setFontSize(12);
    doc.text("Labour Totals Summary", 14, finalY);
    finalY += 6;
    Object.keys(labourGroups).forEach(gst => {
      doc.text(`${gst}%: ₹${labourGroups[gst].toFixed(2)}`, 14, finalY);
      finalY += 6;
    });
    doc.text(`Labour Subtotal: ₹${labourSubtotal.toFixed(2)}`, 14, finalY);
    finalY += 10;
    doc.setFontSize(14);
    doc.text(`Grand Total (Inventory + Labour): ₹${grandTotal.toFixed(2)}`, 14, finalY);
    doc.save("invoice.pdf");
  };

  // Add close job card functionality to JobCardAddInventory component
  const handleCloseJobCard = async () => {
    // Add confirmation dialog
    const confirmClose = window.confirm("Are you sure you want to close this job card? This action cannot be undone.");
    
    if (!confirmClose) return; // Stop if user cancels
  
    try {
      await axios.put(`https://api.mrmechanic.co/api/serviceModule/jobcard/${jobCardId}/close`, {}, {
        headers: { "x-auth-token": authToken }
      });
      
      // Optional: Add success notification
      alert("Job card closed successfully!");
      
      navigate('/service/jobcard');
    } catch (err) {
      console.error(err);
      setError('Failed to close job card');
      // Optional: Show error alert
      alert("Failed to close job card. Please try again.");
    }
  };

  // Close modal when clicking outside the content area
  const closeModal = (e) => {
    if (e.target === e.currentTarget) {
      setShowAddModal(false);
      setShowEditModal(false);
      setShowLabourModal(false);
      setShowEditLabourModal(false);
    }
  };

  if (loading)
    return <div className="loading">Loading inventory...</div>;
  if (error)
    return <div className="error">{error}</div>;
  

  return (
    <div>
      <Navbar />
      <div className="dashboard-container">
        <Sidebar />
        <div className="main-content">
          <div className="page-header">
            <button className="btn btn-back" onClick={() => navigate(-1)} style={{backgroundColor: "red"}}>← Back</button>
            <h2>Job Card Inventory Items</h2>
          </div>
          
          {/* Tax Type Selector */}
          <div className="tax-type-selector" style={{ marginBottom: '20px' }}>
            <span style={{ marginRight: '10px' }}>Tax Type:</span>
            <button 
              className={`btn ${taxType === 'sgst_cgst' ? 'btn-primary' : 'btn-secondary'}`}
              onClick={() => handleTaxTypeToggle('sgst_cgst')}
              style={{ marginRight: '10px' }}
            >
              SGST + CGST
            </button>
            <button 
              className={`btn ${taxType === 'igst' ? 'btn-primary' : 'btn-secondary'}`}
              onClick={() => handleTaxTypeToggle('igst')}
            >
              IGST
            </button>
          </div>
          
          <button className="btn btn-add" onClick={() => setShowAddModal(true)}>Add Inventory</button>
          <table className="table">
          <thead>
              <tr>
                <th className="th">Item Name</th>
                <th className="th">SKU</th>
                <th className="th">Sub Category</th>
                <th className="th">Part Id - Unique Id</th>
                <th className="th">Quantity</th>
                <th className="th">Price</th>
                {taxType === 'igst' ? (
                  <th className="th">IGST Rate</th>
                ) : (
                  <>
                    <th className="th">CGST Rate</th>
                    <th className="th">SGST Rate</th>
                  </>
                )}
                <th className="th">Total</th>
                <th className="th">Actions</th>
              </tr>
            </thead>
            <tbody>
            {jobCardInventory.map(item => {
              const invDetail = allInventory.find(inv => inv._id === (item.inventory?._id || item.inventory));
              let igstRate = 0, sku = "";
              if (invDetail && invDetail.subCategories) {
                const subCat = invDetail.subCategories.find(sub => sub.name === item.subCategoryName);
                igstRate = subCat ? (subCat.igstRate || 0) : 0;
                sku = invDetail.SKU || "";
              }
              return (
                <tr key={item._id}>
                  <td className="td">{item.inventory?.name}</td>
                  <td className="td">{sku}</td>
                  <td className="td">{item.subCategoryName}</td>
                  <td className="td">
                    {(() => {
                      if (!item.uniqueId) return "-";
                      
                      const invDetail = allInventory.find(inv => inv._id === (item.inventory?._id || item.inventory));
                      if (!invDetail || !invDetail.subCategories) return item.uniqueId;
                      
                      const subCat = invDetail.subCategories.find(sub => sub.name === item.subCategoryName);
                      if (!subCat || !subCat.units) return item.uniqueId;
                      
                      const unit = subCat.units.find(u => u.uniqueId === item.uniqueId);
                      return unit && unit.partId ? `${unit.partId} - ${item.uniqueId}` : item.uniqueId;
                    })()}
                  </td>
                  <td className="td">{item.quantity}</td>
                  <td className="td">₹{item.price}</td>        
                  {taxType === 'igst' ? (
                    <td className="td">{igstRate}%</td>
                  ) : (
                    <>
                      <td className="td">{igstRate / 2}%</td>
                      <td className="td">{igstRate / 2}%</td>
                    </>
                  )}                    
                  <td className="td">₹{(item.price * item.quantity).toFixed(2)}</td>
                  <td className="td">
                    <button className="btn btn-edit" onClick={() => { setSelectedItem(item); setEditQuantity(item.quantity); setShowEditModal(true); }}>Edit</button>
                    <button className="btn btn-delete" onClick={() => handleDeleteInventory(item._id)}>Delete</button>
                  </td>
                </tr>
              );
            })}
            </tbody>
          </table>

          {/* Inventory Totals Summary */}
          <div className="summary">
            <h3>Inventory Totals Summary</h3>
            <table className="table">
              <thead>
                <tr>
                  {taxType === 'igst' ? (
                    <th className="th">IGST Rate (%)</th>
                  ) : (
                    <>
                      <th className="th">CGST Rate (%)</th>
                      <th className="th">SGST Rate (%)</th>
                    </>
                  )}
                  <th className="th">Taxable Amount</th>
                  <th className="th">Tax Amount</th>
                  <th className="th">Total Amount</th>
                </tr>
              </thead>
              <tbody>
                {Object.keys(inventoryGroups).map(rate => {
                  const taxableAmount = inventoryGroups[rate];
                  let taxAmount = 0;
                  
                  if (taxType === 'igst') {
                    taxAmount = taxableAmount * (Number(rate) / 100);
                    return (
                      <tr key={rate}>
                        <td className="td">{rate}%</td>
                        <td className="td">₹{taxableAmount.toFixed(2)}</td>
                        <td className="td">₹{taxAmount.toFixed(2)}</td>
                        <td className="td">₹{(taxableAmount + taxAmount).toFixed(2)}</td>
                      </tr>
                    );
                  } else {
                    // For SGST+CGST, rate is stored as "cgst_sgst" format
                    const [cgst, sgst] = rate.split('_').map(Number);
                    taxAmount = taxableAmount * ((cgst + sgst) / 100);
                    return (
                      <tr key={rate}>
                        <td className="td">{cgst}%</td>
                        <td className="td">{sgst}%</td>
                        <td className="td">₹{taxableAmount.toFixed(2)}</td>
                        <td className="td">₹{taxAmount.toFixed(2)}</td>
                        <td className="td">₹{(taxableAmount + taxAmount).toFixed(2)}</td>
                      </tr>
                    );
                  }
                })}
                <tr>
                  <td colSpan={taxType === 'igst' ? 1 : 2} className="td bold">Inventory Subtotal</td>
                  <td className="td bold">₹{inventorySubtotal.toFixed(2)}</td>
                  <td className="td bold">₹{totalTaxAmount.toFixed(2)}</td>
                  <td className="td bold">₹{(inventorySubtotal + totalTaxAmount).toFixed(2)}</td>
                </tr>
              </tbody>
            </table>
          </div>

          {/* Add Inventory Modal */}
          {showAddModal && (
            <div className="modal-overlay" onClick={closeModal}>
              <div className="modal-content" onClick={(e) => e.stopPropagation()}>
                <h3>Add Inventory Item</h3>
                <form onSubmit={handleAddInventory}>
                  <div className="form-group">
                    <label>Inventory Item</label>
                    <select name="inventoryId" value={formData.inventoryId} onChange={handleInventorySelect} className="input-field" required>
                      <option value="">Select an inventory item</option>
                      {allInventory.map(item => (
                        <option key={item._id} value={item._id}>
                          {item.name} - {item.category}
                        </option>
                      ))}
                    </select>
                  </div>
                  {selectedInventory && selectedInventory.subCategories && (
                    <div className="form-group">
                      <label>Sub Category</label>
                      <select name="subCategoryName" value={formData.subCategoryName} onChange={handleSubCategorySelect} className="input-field" required>
                        <option value="">Select a sub category</option>
                        {selectedInventory.subCategories.map(sub => (
                          <option key={sub._id} value={sub.name}>{sub.name}</option>
                        ))}
                      </select>
                    </div>
                  )}
                  {selectedSubCategory && selectedSubCategory.units && selectedSubCategory.units.length > 0 && (
                    <div className="form-group">
                      <label>Unique ID</label>
                      <select name="uniqueId" value={formData.uniqueId} onChange={handleUnitSelect} className="input-field" required>
                        <option value="">Select a unit</option>
                        {selectedSubCategory.units.map(unit => (
                          <option key={unit._id} value={unit.uniqueId}>
                            {unit.partId ? `${unit.partId} - ${unit.uniqueId}` : unit.uniqueId}
                          </option>
                        ))}
                      </select>
                    </div>
                  )}
                  {selectedSubCategory && (
                    <div className="form-group">
                      <label>Quantity</label>
                      <select name="quantity" value={formData.quantity} onChange={handleQuantitySelect} className="input-field" required>
                        {Array.from({ length: selectedSubCategory.quantity }, (_, i) => i + 1).map(num => (
                          <option key={num} value={num}>{num}</option>
                        ))}
                      </select>
                    </div>
                  )}
                 <div className="form-group">
                    <label>Tax Type</label>
                    <select 
                      name="taxType" 
                      value={formData.taxType || taxType} 
                      onChange={handleInputChange} 
                      className="input-field"
                    >
                      <option value="sgst_cgst">SGST + CGST</option>
                      <option value="igst">IGST</option>
                    </select>
                  </div>
                  <div className="modal-btn-group">
                    <button type="button" className="btn btn-secondary" onClick={() => setShowAddModal(false)}>Close</button>
                    <button type="submit" className="btn">Add Item</button>
                  </div>
                </form>
              </div>
            </div>
          )}

          {/* Edit Inventory Quantity Modal */}
          {showEditModal && (
            <div className="modal-overlay" onClick={closeModal}>
              <div className="modal-content" onClick={(e) => e.stopPropagation()}>
                <h3>Edit Quantity</h3>
                <div className="form-group">
                  <label>New Quantity</label>
                  <input type="number" min="1" value={editQuantity} onChange={(e) => setEditQuantity(e.target.value)} className="input-field" />
                </div>
                <div className="modal-btn-group">
                  <button type="button" className="btn btn-secondary" onClick={() => setShowEditModal(false)}>Cancel</button>
                  <button type="button" className="btn" onClick={handleUpdateQuantity}>Save Changes</button>
                </div>
              </div>
            </div>
          )}

          {/* Labour Section */}
          <div className="labour-section">
            <h2>Labour Costs</h2>
            <button className="btn btn-add" onClick={() => setShowLabourModal(true)}>Add Labour</button>
            {labourList.length > 0 ? (
              <table className="table">
                <thead>
                  <tr>
                    <th className="th">S.No</th>
                    <th className="th">Labour Name</th>
                    <th className="th">Unique ID</th>
                    <th className="th">Cost</th>
                    <th className="th">Discount</th>
                    <th className="th">SGST %</th>
                    <th className="th">CGST %</th>
                    <th className="th">Final Amt</th>
                    <th className="th">Actions</th>
                  </tr>
                </thead>
                <tbody>
                  {labourList.map(labour => (
                    <tr key={labour._id}>
                      <td className="td">{labour.serialNumber}</td>
                      <td className="td">{labour.labourName}</td>
                      <td className="td">{labour.labourUniqueId}</td>
                      <td className="td">₹{labour.costOfLabour}</td>
                      <td className="td">₹{labour.discount}</td>
                      <td className="td">{labour.sgstRate}%</td>
                      <td className="td">{labour.cgstRate}%</td>
                      <td className="td">₹{labour.finalAmount}</td>
                      <td className="td">
                        <button className="btn btn-edit" onClick={() => {
                          setSelectedLabour(labour);
                          setLabourFormData({
                            serialNumber: labour.serialNumber,
                            labourName: labour.labourName,
                            labourUniqueId: labour.labourUniqueId,
                            costOfLabour: labour.costOfLabour,
                            discount: labour.discount,
                            sgstRate: labour.sgstRate,
                            cgstRate: labour.cgstRate
                          });
                          setShowEditLabourModal(true);
                        }}>Edit</button>
                        <button className="btn btn-delete" onClick={() => handleDeleteLabour(labour._id)}>Delete</button>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            ) : (
              <p>No labour costs added yet.</p>
            )}

            {/* Labour Totals Summary */}
            <div className="summary">
              <h3>Labour Totals Summary</h3>
              <table className="table">
                <thead>
                  <tr>
                    <th className="th">SGST %</th>
                    <th className="th">CGST %</th>
                    <th className="th">Group Total</th>
                  </tr>
                </thead>
                <tbody>
                  {Object.keys(labourGroups).map(key => {
                    const [sgst, cgst] = key.split('_');
                    return (
                      <tr key={key}>
                        <td className="td">{sgst}%</td>
                        <td className="td">{cgst}%</td>
                        <td className="td">₹{labourGroups[key].toFixed(2)}</td>
                      </tr>
                    );
                  })}
                  <tr>
                    <td className="td bold" colSpan="2">Labour Subtotal</td>
                    <td className="td bold">₹{labourSubtotal.toFixed(2)}</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>

          {/* Grand Total and Generate Invoice Button */}
          <div className="grand-total">
            <div>
              <h3>Grand Total (Inventory + Labour)</h3>
              <p className="total-amount">₹{grandTotal.toFixed(2)}</p>
            </div>
            <div className="action-buttons">
              <button className="btn btn-close" onClick={handleCloseJobCard}>
                Close Job Card
              </button>
              <button className="btn btn-add invoice-btn" onClick={handleGenerateInvoice}>
                Generate Invoice (PDF)
              </button>
            </div>
          </div>

          {/* Add Labour Modal */}
          {showLabourModal && (
            <div className="modal-overlay" onClick={closeModal}>
              <div className="modal-content" onClick={(e) => e.stopPropagation()}>
                <h3>Add Labour Cost</h3>
                <form onSubmit={handleAddLabour}>
                  <input type="text" name="serialNumber" placeholder="Serial Number" value={labourFormData.serialNumber} onChange={handleLabourInputChange} className="input-field" required />
                  <input type="text" name="labourName" placeholder="Labour Name" value={labourFormData.labourName} onChange={handleLabourInputChange} className="input-field" required />
                  <input type="text" name="labourUniqueId" placeholder="Labour Unique ID" value={labourFormData.labourUniqueId} onChange={handleLabourInputChange} className="input-field" required />
                  <input type="number" name="costOfLabour" placeholder="Cost of Labour" value={labourFormData.costOfLabour} onChange={handleLabourInputChange} className="input-field" required />
                  <input type="number" name="discount" placeholder="Discount" value={labourFormData.discount} onChange={handleLabourInputChange} className="input-field" />
                  <input type="number" name="sgstRate" placeholder="SGST Rate" value={labourFormData.sgstRate} onChange={handleLabourInputChange} className="input-field" required />
                  <input type="number" name="cgstRate" placeholder="CGST Rate" value={labourFormData.cgstRate} onChange={handleLabourInputChange} className="input-field" required />
                  <div className="modal-btn-group">
                    <button type="button" className="btn btn-secondary" onClick={() => setShowLabourModal(false)}>Close</button>
                    <button type="submit" className="btn">Add Labour</button>
                  </div>
                </form>
              </div>
            </div>
          )}
          {/* Edit Labour Modal */}
          {showEditLabourModal && (
            <div className="modal-overlay" onClick={closeModal}>
              <div className="modal-content" onClick={(e) => e.stopPropagation()}>
                <h3>Edit Labour Cost</h3>
                <form onSubmit={handleEditLabour}>
                  <input type="text" name="serialNumber" placeholder="Serial Number" value={labourFormData.serialNumber} onChange={handleLabourInputChange} className="input-field" required />
                  <input type="text" name="labourName" placeholder="Labour Name" value={labourFormData.labourName} onChange={handleLabourInputChange} className="input-field" required />
                  <input type="text" name="labourUniqueId" placeholder="Labour Unique ID" value={labourFormData.labourUniqueId} onChange={handleLabourInputChange} className="input-field" required />
                  <input type="number" name="costOfLabour" placeholder="Cost of Labour" value={labourFormData.costOfLabour} onChange={handleLabourInputChange} className="input-field" required />
                  <input type="number" name="discount" placeholder="Discount" value={labourFormData.discount} onChange={handleLabourInputChange} className="input-field" />
                  <input type="number" name="sgstRate" placeholder="SGST Rate" value={labourFormData.sgstRate} onChange={handleLabourInputChange} className="input-field" required />
                  <input type="number" name="cgstRate" placeholder="CGST Rate" value={labourFormData.cgstRate} onChange={handleLabourInputChange} className="input-field" required />
                  <div className="modal-btn-group">
                    <button type="button" className="btn btn-secondary" onClick={() => setShowEditLabourModal(false)}>Close</button>
                    <button type="submit" className="btn">Save Changes</button>
                  </div>
                </form>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default JobCardAddInventory;
