import React from "react";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate,
} from "react-router-dom";
import AuthContext, { AuthProvider } from "./context/AuthContext";
import Dashboard from "./components/Dashboard";
import Main from "./pages/Main";
import Login from "./components/Login";
import Register from "./components/Register";
import AboutUs from "./pages/AboutUs";
import Users from "./pages/Users";
import PartnerList from "./pages/PartnerList";
import Inventory from "./pages/Inventory";
import InventoryUpdates from "./pages/InventoryUpdates";
import StockTransfer from "./pages/StockTransfer";
import Companies from "./pages/Companies";
import Location from "./pages/Location";
import JobCard from "./pages/service/JobCard";
import CreateJobCard from "./pages/service/CreateJobCard";
import JobCardAddInventory from "./pages/service/JobCardAddInventory";
import InventoryItemDetails from "./pages/InventoryItemDetails";

function App() {
  return (
    <Router>
      <AuthProvider>
        <Routes>
          <Route path="/" element={<Main />} />
          <Route path="/login" element={<Login />} />
          <Route path="/register" element={<Register />} />
          <Route path="/aboutUs" element={<AboutUs />} />
          <Route
            path="/dashboard"
            element={
              <PrivateRoute
                roles={["superadmin", "admin", "partner", "customer"]}
              >
                <Dashboard />
              </PrivateRoute>
            }
          />
          <Route
            path="/users"
            element={
              <PrivateRoute roles={["superadmin"]}>
                <Users />
              </PrivateRoute>
            }
          />
          <Route
            path="/partnerList"
            element={
              <PrivateRoute roles={["admin"]}>
                <PartnerList />
              </PrivateRoute>
            }
          />
          <Route
            path="/inventory"
            element={
              <PrivateRoute roles={["superadmin", "admin"]}>
                <Inventory />
              </PrivateRoute>
            }
          />
           <Route
            path="/inventoryItemDetails"
            element={
              <PrivateRoute roles={["superadmin", "admin"]}>
                <InventoryItemDetails />
              </PrivateRoute>
            }
          />
          <Route
            path="/stock-transfer"
            element={
              <PrivateRoute roles={["superadmin", "admin"]}>
                <StockTransfer />
              </PrivateRoute>
            }
          />
          <Route
            path="/inventory-updates"
            element={
              <PrivateRoute roles={["superadmin"]}>
                <InventoryUpdates />
              </PrivateRoute>
            }
          />
          <Route
            path="/inventory/companies"
            element={
              <PrivateRoute roles={["superadmin"]}>
                <Companies />
              </PrivateRoute>
            }
          />
          <Route
            path="/inventory/locations"
            element={
              <PrivateRoute roles={["superadmin"]}>
                <Location />
              </PrivateRoute>
            }
          />
          <Route
            path="/service/create-edit-job-card"
            element={
              <PrivateRoute roles={["superadmin", "admin", "partner", "customer"]}>
                <CreateJobCard />
              </PrivateRoute>
            }
          />
           <Route
            path="/service/jobcard"
            element={
              <PrivateRoute roles={["superadmin", "admin", "partner", "customer"]}>
                <JobCard />
              </PrivateRoute>
            }
          />
          <Route
            path="/service/jobcard-addInventory"
            element={
              <PrivateRoute roles={["superadmin", "admin", "partner", "customer"]}>
                <JobCardAddInventory />
              </PrivateRoute>
            }
          />
          <Route
            path="/service/jobcard-addInventory/:jobCardId"
            element={
              <PrivateRoute roles={["superadmin", "admin", "partner", "customer"]}>
                <JobCardAddInventory />
              </PrivateRoute>
            }
          />
          <Route path="*" element={<Navigate to="/" />} />
        </Routes>
      </AuthProvider>
    </Router>
  );
}

const PrivateRoute = ({ children, roles }) => {
  const { user } = React.useContext(AuthContext);
  if (!user) return <Navigate to="/login" />;
  return roles.includes(user.role) ? children : <Navigate to="/" />;
};

export default App;
