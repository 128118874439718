import { createContext, useState, useEffect } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";

const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const [user, setUser] = useState(null);
  const [token, setToken] = useState(localStorage.getItem("token"));
  const navigate = useNavigate();

  useEffect(() => {
    const initializeUser = async () => {
      if (token) {
        axios.defaults.headers.common["x-auth-token"] = token;
        try {
          const userData = await fetchUser(); // Fetch user once
          setUser(userData);
        } catch (err) {
          console.error("Error during user initialization:", err);
          logout(); // Logout if token is invalid
        }
      }
    };
    initializeUser(); // Invoke the async function
  }, [token]); // Dependency array contains only 'token'

  const login = async (email, password) => {
    try {
      const res = await axios.post("https://api.mrmechanic.co/api/auth/login", {
        email,
        password,
      });
      const newToken = res.data.token;
      setToken(newToken);
      localStorage.setItem("token", newToken);
      axios.defaults.headers.common["x-auth-token"] = newToken;
      const userData = await fetchUser();
      // User data is now saved to localStorage in the fetchUser function
      if (userData) navigate("/dashboard");
    } catch (err) {
      console.error("Login error:", err.response?.data?.message || err.message);
    }
  };

  const register = async (name, email, password, role, company) => {
    try {
      const res = await axios.post(
        "https://api.mrmechanic.co/api/auth/register",
        {
          name,
          email,
          password,
          role,
          company,
        }
      );
      return res.data;
    } catch (err) {
      console.error(
        "Registration error:",
        err.response?.data?.message || err.message
      );
      throw err;
    }
  };

  const fetchUser = async () => {
    try {
      const res = await axios.get("https://api.mrmechanic.co/api/auth/profile");
      // Save user data to localStorage
      localStorage.setItem("user", JSON.stringify(res.data));
      return res.data;
    } catch (err) {
      console.error("Fetch user error:", err);
      throw err;
    }
  };

  const logout = () => {
    setToken(null);
    setUser(null);
    localStorage.removeItem("token");
    localStorage.removeItem("user");
    delete axios.defaults.headers.common["x-auth-token"];
    navigate("/login");
  };

  return (
    <AuthContext.Provider value={{ user, token, setUser, login, register, logout }}>
      {children}
    </AuthContext.Provider>
  );
};

export default AuthContext;
