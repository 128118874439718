import React, { useEffect, useState } from "react";
import Navbar from "../components/Navbar";
import Sidebar from "../components/Sidebar";
import "./StockTransfer.css";

function StockTransfer() {
  const [inventories, setInventories] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [companyFilter, setCompanyFilter] = useState("");
  const [showModal, setShowModal] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null);
  const [selectedCategory, setSelectedCategory] = useState("");
  const [selectedSubCategory, setSelectedSubCategory] = useState("");
  const [selectedUnits, setSelectedUnits] = useState([]);
  const [loading, setLoading] = useState(true);

  // Fetch inventories from the backend API
  useEffect(() => {
    const headers = new Headers({
      "x-auth-token": localStorage.getItem("token"),
      "Content-Type": "application/json",
    });

    fetch("https://api.mrmechanic.co/api/inventory", {
      method: "GET",
      headers,
    })
      .then((response) => response.json())
      .then((data) => {
        // Sort inventories by creation date in descending order (most recent first)
        const sortedData = data.sort(
          (a, b) => new Date(b.createdAt) - new Date(a.createdAt)
        );
        setInventories(Array.isArray(sortedData) ? sortedData : []);
      })
      .catch((error) =>
        console.error("Error fetching inventories:", error)
      )
      .finally(() => setLoading(false));
  }, []);

  const filteredInventories = inventories.filter((item) => {
    const matchesSearchTerm = item.name
      .toLowerCase()
      .includes(searchTerm.toLowerCase());
    const matchesCompanyFilter = companyFilter
      ? item.company === companyFilter
      : true;
    return matchesSearchTerm && matchesCompanyFilter;
  });

  const companies = [...new Set(inventories.map((item) => item.company))];

  // Handle category and subcategory selection
  const handleCategorySelect = (category) => {
    setSelectedCategory(category);
    setSelectedSubCategory(""); // Reset subcategory when category changes
  };

  const handleSubCategorySelect = (subCategory) =>
    setSelectedSubCategory(subCategory);

  // Handle unit selection (multiple units)
  const handleUnitSelect = (unitId) => {
    setSelectedUnits((prevUnits) =>
      prevUnits.includes(unitId)
        ? prevUnits.filter((id) => id !== unitId)
        : [...prevUnits, unitId]
    );
  };

  // Open modal and set selected item
  const handleTransferClick = (item) => {
    setSelectedItem(item);
    setShowModal(true);
  };

  // Close modal
  const closeModal = () => {
    setShowModal(false);
    setSelectedItem(null);
    setSelectedCategory("");
    setSelectedSubCategory("");
    setSelectedUnits([]);
  };

  // Transfer function to handle stock transfer action
  const handleStockTransfer = () => {
    setLoading(true);
    // API request for stock transfer would go here.
    // After the request, setLoading(false) to hide the "loading" message.
  };

  return (
    <div>
      <Navbar />
      <div className="dashboard-container">
        <Sidebar />
        <div className="main-content">
          <h2>Stock Transfer</h2>

          {/* Search bar and company filter */}
          <div className="search-container">
            <input
              type="text"
              placeholder="Search by name"
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
              className="input-field"
            />
            <select
              value={companyFilter}
              onChange={(e) => setCompanyFilter(e.target.value)}
              className="select-field"
            >
              <option value="">All Companies</option>
              {companies.map((company) => (
                <option key={company} value={company}>
                  {company}
                </option>
              ))}
            </select>
          </div>

          {loading && <p className="loading-text">Stock Transfer is loading...</p>}

          {/* Inventory Table */}
          <table className="inventory-table">
            <thead>
              <tr className="table-header">
                <th className="table-cell">Name</th>
                <th className="table-cell">Description</th>
                <th className="table-cell">Category</th>
                <th className="table-cell">Company</th>
                <th className="table-cell">Price</th>
                <th className="table-cell">Quantity</th>
                <th className="table-cell">Action</th>
              </tr>
            </thead>
            <tbody>
              {loading ? (
                <tr>
                  <td colSpan="7" className="table-cell">
                    Loading...
                  </td>
                </tr>
              ) : filteredInventories.length > 0 ? (
                filteredInventories.map((item) => (
                  <tr key={item._id} className="table-row">
                    <td className="table-cell">{item.name}</td>
                    <td className="table-cell">{item.description}</td>
                    <td className="table-cell">{item.category}</td>
                    <td className="table-cell">{item.company}</td>
                    <td className="table-cell">
                      {item.subCategories.map((sub) => (
                        <div key={sub.name}>
                          {sub.name}: Rs {sub.price.toFixed(2)}
                        </div>
                      ))}
                    </td>
                    <td className="table-cell">
                      {item.subCategories.map((sub) => (
                        <div
                          key={sub.name}
                          className="quantity-row"
                        >
                          {sub.name}: {sub.quantity}
                        </div>
                      ))}
                    </td>
                    <td className="table-cell">
                      <button
                        onClick={() => handleTransferClick(item)}
                        className="transfer-btn"
                      >
                        Transfer
                      </button>
                    </td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td colSpan="7" className="table-cell">
                    No inventories found
                  </td>
                </tr>
              )}
            </tbody>
          </table>

          {/* Transfer Modal */}
          {showModal && selectedItem && (
            <div className="modal-backdrop" onClick={closeModal}>
              <div className="modal-content" onClick={(e) => e.stopPropagation()}>
                <h3>Transfer Details</h3>
                <p>Name: {selectedItem?.name}</p>
                <p>Description: {selectedItem?.description}</p>

                {/* Category and subcategory selection */}
                <div className="form-group">
                  <label>Select Category:</label>
                  <select
                    value={selectedCategory}
                    onChange={(e) => handleCategorySelect(e.target.value)}
                    className="input-field"
                  >
                    <option value="">Select Category</option>
                    {selectedItem?.subCategories.map((sub, index) => (
                      <option key={index} value={sub.name}>
                        {sub.name}
                      </option>
                    ))}
                  </select>
                </div>

                {selectedCategory && (
                  <div className="form-group">
                    <label>Select Subcategory:</label>
                    <select
                      value={selectedSubCategory}
                      onChange={(e) => handleSubCategorySelect(e.target.value)}
                      className="input-field"
                    >
                      <option value="">Select Subcategory</option>
                      {selectedItem?.subCategories
                        .filter((sub) => sub.name === selectedCategory)
                        .map((sub, index) => (
                          <option key={index} value={sub.name}>
                            {sub.name}
                          </option>
                        ))}
                    </select>
                  </div>
                )}

                {/* Unit Selection */}
                {selectedSubCategory && (
                  <div className="form-group">
                    <label>Select Units:</label>
                    <div className="units-container">
                      {selectedItem?.subCategories
                        .filter((sub) => sub.name === selectedSubCategory)[0]
                        .units.map((unit) => (
                          <div key={unit._id} className="unit-option">
                            <input
                              type="checkbox"
                              id={`unit-${unit._id}`}
                              onChange={() => handleUnitSelect(unit._id)}
                            />
                            <label htmlFor={`unit-${unit._id}`}>
                              {unit.uniqueId}
                            </label>
                          </div>
                        ))}
                    </div>
                  </div>
                )}

                <div className="modal-btn-group">
                  <button onClick={closeModal} className="btn btn-secondary">
                    Close
                  </button>
                  <button onClick={handleStockTransfer} className="btn btn-primary">
                    Transfer
                  </button>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default StockTransfer;
