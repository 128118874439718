import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Navbar from "../../components/Navbar";
import Sidebar from "../../components/Sidebar";
import "./JobCard.css";

const getAuthToken = () => localStorage.getItem("token");

function JobCard() {
  const [jobcards, setJobcards] = useState([]);
  const [filteredJobcards, setFilteredJobcards] = useState([]);
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const navigate = useNavigate();

  const fetchJobcards = async () => {
    const token = getAuthToken();
    if (!token) return;

    try {
      const res = await fetch("https://api.mrmechanic.co/api/serviceModule/jobcards", {
        method: "GET",
        headers: {
          "x-auth-token": token,
          "Content-Type": "application/json",
        },
      });
      if (!res.ok) throw new Error("Failed to fetch job cards");
      const data = await res.json();
      setJobcards(data);
      setFilteredJobcards(data); // Initially set filtered to all
    } catch (error) {
      console.error("Error fetching jobcards:", error);
    }
  };

  useEffect(() => {
    fetchJobcards();
    
    // Set default end date to today
    const today = new Date();
    setEndDate(formatDateForInput(today));
    
    // Set default start date to 30 days ago
    const thirtyDaysAgo = new Date();
    thirtyDaysAgo.setDate(today.getDate() - 30);
    setStartDate(formatDateForInput(thirtyDaysAgo));
  }, []);

  // Format date for input field (YYYY-MM-DD)
  const formatDateForInput = (date) => {
    return date.toISOString().split('T')[0];
  };

  // Apply date filter
  const applyDateFilter = () => {
    if (!startDate && !endDate) {
      setFilteredJobcards(jobcards);
      return;
    }

    const filtered = jobcards.filter(card => {
      const cardDate = new Date(card.createdAt);
      let matchesFilter = true;
      
      if (startDate) {
        const startFilterDate = new Date(startDate);
        startFilterDate.setHours(0, 0, 0, 0);
        matchesFilter = matchesFilter && cardDate >= startFilterDate;
      }
      
      if (endDate) {
        const endFilterDate = new Date(endDate);
        endFilterDate.setHours(23, 59, 59, 999);
        matchesFilter = matchesFilter && cardDate <= endFilterDate;
      }
      
      return matchesFilter;
    });

    setFilteredJobcards(filtered);
  };

  // Clear date filters
  const clearDateFilter = () => {
    // Set default end date to today
    const today = new Date();
    setEndDate(formatDateForInput(today));
    
    // Set default start date to 30 days ago
    const thirtyDaysAgo = new Date();
    thirtyDaysAgo.setDate(today.getDate() - 30);
    setStartDate(formatDateForInput(thirtyDaysAgo));
    
    setFilteredJobcards(jobcards);
  };

  useEffect(() => {
    // Reapply filter when dates change
    if (jobcards.length > 0) {
      applyDateFilter();
    }
  }, [startDate, endDate]);

  const categorizeJobCards = (data) => {
    const now = new Date();
    const todayStart = new Date(now.getFullYear(), now.getMonth(), now.getDate());
    
    return data.reduce((acc, jc) => {
      if (jc.status !== 'completed') {
        acc.active.push(jc);
      } else {
        const completedDate = new Date(jc.completedAt);
        if (completedDate >= todayStart) {
          acc.closedToday.push(jc);
        } else {
          acc.olderClosed.push(jc);
        }
      }
      return acc;
    }, { active: [], closedToday: [], olderClosed: [] });
  };

  const { active, closedToday, olderClosed } = categorizeJobCards(filteredJobcards);

  const handleJobCardClick = (jobCardId) => {
    navigate(`/service/jobcard-addInventory/${jobCardId}`);
  };

  return (
    <div className="jobcard-page">
      <Navbar />
      <div className="jobcard-container">
        <Sidebar />
        <div className="main-content">
          <h1>Job Cards</h1>
          
          {/* Date Filter Controls */}
          <div className="date-filter-container">
            <div className="date-inputs">
              <div className="date-field">
                <label htmlFor="start-date">From Date:</label>
                <input
                  type="date"
                  id="start-date"
                  value={startDate}
                  onChange={(e) => setStartDate(e.target.value)}
                />
              </div>
              <div className="date-field">
                <label htmlFor="end-date">To Date:</label>
                <input
                  type="date"
                  id="end-date"
                  value={endDate}
                  onChange={(e) => setEndDate(e.target.value)}
                />
              </div>
            </div>
            <div className="date-buttons">
              <button className="btn-filter" onClick={applyDateFilter}>Apply Filter</button>
              <button className="btn-clear" onClick={clearDateFilter}>Reset</button>
            </div>
          </div>
          
          {/* Filter Results Summary */}
          <div className="filter-summary">
            <p>Showing {filteredJobcards.length} job cards from {startDate || "earliest"} to {endDate || "latest"}</p>
          </div>
          
          {/* Active Cards - Grid View */}
          <h3>Active Job Cards ({active.length})</h3>
          <div className="grid-container">
            {active.map((jobcard) => (
              <div
                key={jobcard._id}
                className="jobcard-card"
                onClick={() => handleJobCardClick(jobcard._id)}
              >
                <h3>{jobcard.name} ({jobcard.registrationNumber})</h3>
                <p style={{ color: '#28a745' }}>
                  <strong>Status:</strong> Active
                </p>
                <p>
                  <strong>Brand:</strong> {jobcard.brand}
                </p>
                <p>
                  <strong>Year:</strong> {jobcard.year}
                </p>
                {jobcard.owner && (
                  <p>
                    <strong>Owner:</strong> {jobcard.owner.name} ({jobcard.owner.email})
                  </p>
                )}
                <p>
                  <strong>Created:</strong> {new Date(jobcard.createdAt).toLocaleDateString()}
                </p>
              </div>
            ))}
          </div>

          {active.length === 0 && <p>No active job cards found in the selected date range.</p>}

          {/* Today's Closed Cards - Table View */}
          {closedToday.length > 0 && (
            <div className="closed-section">
              <h3>Today's Closed Job Cards ({closedToday.length})</h3>
              <table className="closed-table">
                <thead>
                  <tr>
                    <th>Registration</th>
                    <th>Brand</th>
                    <th>Name</th>
                    <th>Year</th>
                    <th>Closed Date</th>
                    <th>Owner</th>
                    <th>Actions</th>
                  </tr>
                </thead>
                <tbody>
                  {closedToday.map(jc => (
                    <tr key={jc._id}>
                      <td>{jc.registrationNumber}</td>
                      <td>{jc.brand}</td>
                      <td>{jc.name}</td>
                      <td>{jc.year}</td>
                      <td>{new Date(jc.completedAt).toLocaleDateString()}</td>
                      <td>{jc.owner?.name || 'N/A'}</td>
                      <td>
                        <button 
                          className="btn-view" 
                          onClick={() => handleJobCardClick(jc._id)}
                        >
                          View Details
                        </button>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          )}

          {/* Older Closed Cards - Table View */}
          {olderClosed.length > 0 && (
            <div className="older-closed-section">
              <h3>Previously Closed Job Cards ({olderClosed.length})</h3>
              <table className="closed-table">
                <thead>
                  <tr>
                    <th>Registration</th>
                    <th>Brand</th>
                    <th>Name</th>
                    <th>Year</th>
                    <th>Closed Date</th>
                    <th>Owner</th>
                    <th>Actions</th>
                  </tr>
                </thead>
                <tbody>
                  {olderClosed.map(jc => (
                    <tr key={jc._id}>
                      <td>{jc.registrationNumber}</td>
                      <td>{jc.brand}</td>
                      <td>{jc.name}</td>
                      <td>{jc.year}</td>
                      <td>{new Date(jc.completedAt).toLocaleDateString()}</td>
                      <td>{jc.owner?.name || 'N/A'}</td>
                      <td>
                        <button 
                          className="btn-view" 
                          onClick={() => handleJobCardClick(jc._id)}
                        >
                          View Details
                        </button>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          )}

          {filteredJobcards.length === 0 && <p>No job cards found matching the selected date range.</p>}
        </div>
      </div>
    </div>
  );
}

export default JobCard;