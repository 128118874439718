import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import Navbar from "../components/Navbar";
import Sidebar from "../components/Sidebar";
import "./InventoryItemDetails.css";

function InventoryItemDetails() {
  const [itemDetails, setItemDetails] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [locations, setLocations] = useState([]);
  const [showTransferDialog, setShowTransferDialog] = useState(false);
  const [transferDetails, setTransferDetails] = useState({
    subCategoryName: "",
    newLocation: "",
    transferQuantity: 0,
    uniqueIds: [],
  });
  const [selectedUnits, setSelectedUnits] = useState([]);
  const [transferStatus, setTransferStatus] = useState({ message: "", isError: false });
  const [searchCompany, setSearchCompany] = useState("");
  const [searchLocation, setSearchLocation] = useState("");
  const [editingPartId, setEditingPartId] = useState(null);
  const [newPartId, setNewPartId] = useState('');
  const loc = useLocation();

  // Fetch item details
  useEffect(() => {
    const fetchItemDetails = async () => {
      const itemId = new URLSearchParams(loc.search).get("id");
      if (!itemId) {
        setError("No item ID provided");
        setLoading(false);
        return;
      }
      try {
        const headers = new Headers({
          "x-auth-token": localStorage.getItem("token"),
          "Content-Type": "application/json",
        });
        const response = await fetch(`http://localhost:5000/api/inventory/${itemId}`, {
          method: "GET",
          headers,
        });
        if (!response.ok) throw new Error("Failed to fetch item details");
        setItemDetails(await response.json());
        setLoading(false);
      } catch (err) {
        setError(err.message);
        setLoading(false);
      }
    };
    fetchItemDetails();
  }, [loc]);

  // Fetch locations for transfer dropdown
  useEffect(() => {
    const fetchLocations = async () => {
      try {
        const headers = new Headers({
          "x-auth-token": localStorage.getItem("token"),
          "Content-Type": "application/json",
        });
        const response = await fetch("http://localhost:5000/api/location", {
          method: "GET",
          headers,
        });
        if (!response.ok) throw new Error("Failed to fetch locations");
        setLocations(await response.json());
      } catch (err) {
        console.error("Error fetching locations:", err);
      }
    };
    fetchLocations();
  }, []);

  // Handle stock transfer
  const handleTransfer = async () => {
    if (!transferDetails.newLocation) {
      setTransferStatus({ message: "Please select a destination location", isError: true });
      return;
    }
    if (selectedUnits.length === 0) {
      setTransferStatus({ message: "Please select units to transfer", isError: true });
      return;
    }
    try {
      const itemId = new URLSearchParams(loc.search).get("id");
      const response = await fetch(`http://localhost:5000/api/inventory/transfer/${itemId}`, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          "x-auth-token": localStorage.getItem("token"),
        },
        body: JSON.stringify({
          subCategoryName: transferDetails.subCategoryName,
          newLocation: transferDetails.newLocation,
          transferQuantity: selectedUnits.length,
          uniqueIds: selectedUnits,
        }),
      });
      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.message);
      }
      // Refresh item details after transfer
      const updatedResponse = await fetch(`http://localhost:5000/api/inventory/${itemId}`, {
        headers: { "x-auth-token": localStorage.getItem("token") },
      });
      setItemDetails(await updatedResponse.json());
      setTransferStatus({ message: "Transfer successful!", isError: false });
      setShowTransferDialog(false);
      setSelectedUnits([]);
    } catch (err) {
      setTransferStatus({ message: err.message, isError: true });
    }
  };

  const handlePartIdUpdate = async (subCategoryName, uniqueId) => {
    try {
      const itemId = new URLSearchParams(loc.search).get("id");
      const response = await fetch(`http://localhost:5000/api/inventory/${itemId}`, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          "x-auth-token": localStorage.getItem("token"),
        },
        body: JSON.stringify({
          subCategories: [{
            name: subCategoryName,
            units: [{ 
              uniqueId: uniqueId, 
              partId: newPartId 
            }]
          }]
        }),
      });
  
      // Log the raw response for debugging
      const responseText = await response.text();
      console.log('Raw response:', responseText);
  
      // Try to parse the response
      let responseData;
      try {
        responseData = JSON.parse(responseText);
      } catch (parseError) {
        console.error('Failed to parse response:', parseError);
        setError('Invalid server response');
        return;
      }
  
      if (!response.ok) {
        // Handle error response
        setError(responseData.message || 'Unknown error occurred');
        return;
      }
  
      // Refresh item details
      setItemDetails(responseData);
      
      // Reset editing state
      setEditingPartId(null);
      setNewPartId('');
      setError(null);
    } catch (err) {
      console.error("Error updating Part ID:", err);
      setError(err.message || 'Failed to update Part ID');
    }
  };
  
  const handleUnitSelection = (uniqueId, subCategoryName) => {
    setSelectedUnits((prev) =>
      prev.includes(uniqueId) ? prev.filter((id) => id !== uniqueId) : [...prev, uniqueId]
    );
    setTransferDetails((prev) => ({ ...prev, subCategoryName }));
  };

  // Determine company name and filter subcategories by location search
  const companyName =
    itemDetails &&
    (typeof itemDetails.company === "object" && itemDetails.company !== null
      ? itemDetails.company.name
      : itemDetails.company);
  const companyMatches =
    !searchCompany || (companyName && companyName.toLowerCase().includes(searchCompany.toLowerCase()));
  const filteredSubCategories = itemDetails
    ? itemDetails.subCategories.filter((sub) => {
        if (searchLocation) {
          const locName =
            (typeof sub.location === "object" && sub.location !== null ? sub.location.name : sub.location) || "";
          return locName.toLowerCase().includes(searchLocation.toLowerCase());
        }
        return true;
      })
    : [];

  if (loading)
    return (
      <div className="dashboard">
        <div className="sidebar">
          <Sidebar />
        </div>
        <div className="content">Loading...</div>
      </div>
    );
  if (error)
    return (
      <div className="dashboard">
        <div className="navbar">
          <Navbar />
        </div>
        <div className="sidebar">
          <Sidebar />
        </div>
        <div className="content">Error: {error}</div>
      </div>
    );

  return (
    <div className="dashboard">
      <div className="navbar">
        <Navbar />
      </div>
      <div className="sidebar">
        <Sidebar />
      </div>
      <div className="content">
        <div className="search-container">
          <input
            type="text"
            placeholder="Search by Company"
            value={searchCompany}
            onChange={(e) => setSearchCompany(e.target.value)}
            className="input-field"
          />
          <input
            type="text"
            placeholder="Search by Location"
            value={searchLocation}
            onChange={(e) => setSearchLocation(e.target.value)}
            className="input-field"
          />
        </div>
        {companyMatches ? (
          <>
            <div className="header-row">
              <h1 className="title">{itemDetails.name} - Details</h1>
              <button className="btn btn-primary" onClick={() => setShowTransferDialog(true)}>
                Transfer Stock
              </button>
            </div>
            <div className="item-info">
              <p>
                <strong>Category:</strong> {itemDetails.category}
              </p>
              <p>
                <strong>Description:</strong> {itemDetails.description}
              </p>
              <p>
                <strong>HSN Code:</strong> {itemDetails.SKU}
              </p>
              <p>
                <strong>Company:</strong> {companyName}
              </p>
            </div>
            {filteredSubCategories.map((subCategory, index) => (
              <div key={index} className="subcategory-section">
                <h2 className="subcategory-title">Subcategory: {subCategory.name}</h2>
                <table className="details-table">
                  <thead>
                    <tr>
                     <th>Select</th>
                      <th>Unit ID</th>
                      <th>Part ID</th>
                      <th>Location</th>
                      <th>Company</th>
                      <th>Price</th>
                      <th>Type</th>
                      <th>Unit</th>
                      <th>Status</th>
                    </tr>
                  </thead>
                  <tbody>
                    {subCategory.units.map((unit, i) => (
                      <tr key={i}>
                        <td>
                          <input
                            type="checkbox"
                            checked={selectedUnits.includes(unit.uniqueId)}
                            onChange={() => handleUnitSelection(unit.uniqueId, subCategory.name)}
                          />
                        </td>
                        <td>{unit.uniqueId}</td>

                        <td>
                      {editingPartId === unit.uniqueId ? (
                        <div className="part-id-edit">
                          <input
                            type="text"
                            value={newPartId}
                            onChange={(e) => setNewPartId(e.target.value)}
                            placeholder="Enter Part ID"
                          />
                          <button 
                            onClick={() => handlePartIdUpdate(subCategory.name, unit.uniqueId)}
                            className="btn btn-primary btn-sm"
                          >
                            Save
                          </button>
                          <button 
                            onClick={() => {
                              setEditingPartId(null);
                              setNewPartId('');
                            }}
                            className="btn btn-secondary btn-sm"
                          >
                            Cancel
                          </button>
                        </div>
                      ) : (
                        <div className="part-id-display">
                          {unit.partId || 'Not set'}
                          <button 
                            onClick={() => {
                              setEditingPartId(unit.uniqueId);
                              setNewPartId(unit.partId || '');
                            }}
                            className="btn btn-link btn-sm"
                          >
                            Edit
                          </button>
                        </div>
                      )}
                    </td>


                        <td>
                          {(typeof subCategory.location === "object" && subCategory.location !== null)
                            ? subCategory.location.name
                            : subCategory.location || "N/A"}
                        </td>
                        <td>{companyName}</td>
                        <td>{subCategory.price}</td>
                        <td>{subCategory.type}</td>
                        <td>{subCategory.unit}</td>
                        <td>Active</td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            ))}
          </>
        ) : (
          <p>No matching company records.</p>
        )}
        {showTransferDialog && (
          <>
            <div className="modal-backdrop" onClick={() => setShowTransferDialog(false)} />
            <div className="modal">
              <h2 className="modal-title">Transfer Stock</h2>
              <p className="modal-subtitle">Select the destination location for the transfer.</p>
              <select
                className="select-field"
                value={transferDetails.newLocation}
                onChange={(e) =>
                  setTransferDetails({ ...transferDetails, newLocation: e.target.value })
                }
              >
                <option value="">Select destination location</option>
                {locations.map((loc) => (
                  <option key={loc._id} value={loc._id}>
                    {loc.name}
                  </option>
                ))}
              </select>
              {transferStatus.message && (
                <div className={`transfer-status ${transferStatus.isError ? "error" : "success"}`}>
                  {transferStatus.message}
                </div>
              )}
              <div className="modal-btn-group">
                <button
                  className="btn btn-secondary"
                  onClick={() => {
                    setShowTransferDialog(false);
                    setSelectedUnits([]);
                    setTransferStatus({ message: "", isError: false });
                  }}
                >
                  Cancel
                </button>
                <button className="btn btn-primary" onClick={handleTransfer}>
                  Transfer
                </button>
              </div>
            </div>
          </>
        )}
      </div>
    </div>
  );
}

export default InventoryItemDetails;
