import React, { useState, useEffect } from "react";
import Navbar from "../../components/Navbar";
import Sidebar from "../../components/Sidebar";
import { useNavigate } from "react-router-dom";
import "./CreateJobCard.css";

function CreateJobCard() {
  const navigate = useNavigate();
  const [bikes, setBikes] = useState([]);
  const [selectedBike, setSelectedBike] = useState(null);
  const [bikeHistory, setBikeHistory] = useState([]);
  const [showDetailsModal, setShowDetailsModal] = useState(false);
  const [showHistoryModal, setShowHistoryModal] = useState(false);
  const [showCreateJobCardModal, setShowCreateJobCardModal] = useState(false);
  const [formData, setFormData] = useState({ name: "", mobile: "", altMobile: "", email: "", city: "", state: "", bikeBrand: "", bikeYear: "", bikeName: "", bikeRegistrationNumber: "",});
  const [registrationNumber, setRegistrationNumber] = useState("");
  const [showSuccessPopup, setShowSuccessPopup] = useState(false);
  const [showJobCardCreationModal, setShowJobCardCreationModal] = useState(false);
  const [jobCardData, setJobCardData] = useState({ registrationNumber: "", brand: "", name: "", year: "", ownerEmail: "",});

  const getAuthToken = () => {
    const token = localStorage.getItem("token");
    if (!token) {
      console.error("No token found in localStorage");
    }
    return token;
  };

  const fetchBikes = async () => {
    const token = getAuthToken();
    if (!token) return;

    const myHeaders = new Headers();
    myHeaders.append("x-auth-token", token);
    myHeaders.append("Content-Type", "application/json");

    const requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };

    try {
      const response = await fetch(
        "https://api.mrmechanic.co/api/serviceModule/bikes",
        requestOptions
      );
      if (!response.ok) {
        throw new Error("Failed to fetch bikes");
      }
      const result = await response.json();
      setBikes(result);
    } catch (error) {
      console.error("Error fetching bikes:", error);
    }
  };

  const fetchBikeDetails = async (registrationNumber) => {
    const token = getAuthToken();
    if (!token) return;

    const myHeaders = new Headers();
    myHeaders.append("x-auth-token", token);
    myHeaders.append("Content-Type", "application/json");

    const requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };

    try {
      const response = await fetch(
        `https://api.mrmechanic.co/api/serviceModule/bike/${registrationNumber}`,
        requestOptions
      );
      if (!response.ok) {
        throw new Error("Failed to fetch Vehicle details");
      }
      const result = await response.json();
      setSelectedBike(result);
      setShowDetailsModal(true);
    } catch (error) {
      console.error("Error fetching Vehicle details:", error);
      // If the vehicle is not found, open the job card modal to create a new one
      setShowCreateJobCardModal(true);
    }
  };

  const handleRegistrationSubmit = () => {
    if (!registrationNumber) {
      alert("Please enter a registration number");
      return;
    }
    if (!validateRegistrationNumber(registrationNumber)) {
      alert(
        "Please enter a valid registration number format (e.g., MH12AB1234)"
      );
      return;
    }
    fetchBikeDetails(registrationNumber);
  };

  const fetchBikeHistory = async (registrationNumber) => {
    const token = getAuthToken();
    if (!token) return;

    const myHeaders = new Headers();
    myHeaders.append("x-auth-token", token);
    myHeaders.append("Content-Type", "application/json");

    const requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };

    try {
      const response = await fetch(
        `https://api.mrmechanic.co/api/serviceModule/bike-history/${registrationNumber}`,
        requestOptions
      );
      if (!response.ok) {
        throw new Error("Failed to fetch Vehicle history");
      }
      const result = await response.json();
      setBikeHistory(result.history);
      setShowHistoryModal(true);
    } catch (error) {
      console.error("Error fetching Vehicle history:", error);
    }
  };

  useEffect(() => {
    fetchBikes();
  }, []);

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = async () => {
    const token = localStorage.getItem("token");
    if (!token) return alert("No token found");

    // Validate required fields
    if (
      !formData.name ||
      !formData.mobile ||
      !formData.email ||
      !formData.bikeBrand ||
      !formData.bikeName
    ) {
      alert("Please fill in all required fields");
      return;
    }

    // Include the registration number from the search field
    const submissionData = {
      ...formData,
      bikeRegistrationNumber: registrationNumber,
    };

    const myHeaders = new Headers();
    myHeaders.append("x-auth-token", token);
    myHeaders.append("Content-Type", "application/json");

    const requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: JSON.stringify(submissionData),
      redirect: "follow",
    };

    try {
      const response = await fetch(
        "https://api.mrmechanic.co/api/serviceModule/register-bike",
        requestOptions
      );
      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.message || "Failed to create job card");
      }
      const result = await response.json();
      if (result.message === "Vehicle already registered") {
        // Show modal with existing vehicle details
        fetchBikeDetails(submissionData.bikeRegistrationNumber);
      } else {
        alert("Vehicle Registered Successfully and Job card created!");
        setShowCreateJobCardModal(false);
        // Reset form data
        setFormData({ name: "", mobile: "", altMobile: "", email: "", city: "", state: "", bikeBrand: "", bikeYear: "", bikeName: "", bikeRegistrationNumber: "",});
        // Refresh the vehicles list
        fetchBikes();
      }
    } catch (error) {
      console.error("Error:", error);
      alert(error.message || "Failed to create job card");
    }
  };

  const validateRegistrationNumber = (number) => {
    // Basic regex validation for registration number format
    const regex = /^[A-Z]{2}[0-9]{1,2}[A-Z]{1,2}[0-9]{4}$/;
    return regex.test(number);
  };

  const handleJobCardSubmit = async () => {
    const token = localStorage.getItem("token");
    if (!token) return alert("No token found");

    const myHeaders = new Headers();
    myHeaders.append("x-auth-token", token);
    myHeaders.append("Content-Type", "application/json");

    const raw = JSON.stringify({
      registrationNumber: jobCardData.registrationNumber,
      brand: jobCardData.brand,
      name: jobCardData.name,
      year: jobCardData.year,
      ownerEmail: jobCardData.ownerEmail,
    });

    const requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    try {
      const response = await fetch(
        "https://api.mrmechanic.co/api/serviceModule/jobcard",
        requestOptions
      );
      if (!response.ok) {
        throw new Error("Failed to create job card");
      }
      const result = await response.json();
      console.log(result);
      // Show the success popup and navigate after 2 seconds
      setShowJobCardCreationModal(false);
      setShowSuccessPopup(true);
      setTimeout(() => {
        setShowSuccessPopup(false);
        navigate("/service/jobcard");
      }, 2000);
    } catch (error) {
      console.error("Error creating job card:", error);
      alert("Error creating job card");
    }
  };

  // Close modal when clicking outside of the content area
  const closeModal = (e) => {
    if (e.target === e.currentTarget) {
      setShowCreateJobCardModal(false);
      setShowDetailsModal(false);
      setShowHistoryModal(false);
    }
  };

  return (
    <div>
      <Navbar />
      <div className="dashboard-container">
        <Sidebar />
        <div className="main-content">
          <input
            type="text"
            placeholder="Enter Registration Number"
            value={registrationNumber}
            onChange={(e) => setRegistrationNumber(e.target.value)}
            className="input-field"
          />
          <button onClick={handleRegistrationSubmit} className="btn btn-primary">
            Search Vehicle
          </button>
          <h1>Registered Vehicles</h1>
          {bikes.length === 0 ? (
            <p>No Vehicles found.</p>
          ) : (
            <table className="responsive-table">
              <thead>
                <tr>
                  <th>Registration Number</th>
                  <th>Brand</th>
                  <th>Year</th>
                  <th>Name</th>
                  <th>Owner Name</th>
                  <th>Owner Email</th>
                  <th>isDeleted</th>
                  <th>Actions</th>
                </tr>
              </thead>
              <tbody>
                {bikes.map((bike) => (
                  <tr key={bike._id}>
                    <td data-label="Registration Number">{bike.registrationNumber}</td>
                    <td data-label="Brand">{bike.brand}</td>
                    <td data-label="Year">{bike.year}</td>
                    <td data-label="Name">{bike.name}</td>
                    <td data-label="Owner Name">{bike.owner?.name}</td>
                    <td data-label="Owner Email">{bike.owner?.email}</td>
                    <td data-label="isDeleted">{bike.isDeleted ? "Yes" : "No"}</td>
                    <td data-label="Actions">
                      <button
                        onClick={() => fetchBikeDetails(bike.registrationNumber)}
                        className="btn btn-secondary"
                      >
                        Get Details
                      </button>{" "}
                      <button
                        onClick={() => fetchBikeHistory(bike.registrationNumber)}
                        className="btn btn-secondary"
                      >
                        Get History
                      </button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          )}

          {/* Register Vehicle Modal */}
          {showCreateJobCardModal && (
            <div className="modal-overlay" onClick={closeModal}>
              <div className="modal-content" onClick={(e) => e.stopPropagation()} style={{
                maxHeight: '80vh',
                overflowY: 'auto'
              }}>
                <h2>Register Vehicle</h2>
                {["name", "city", "state", "bikeBrand", "bikeName"].map((field) => (
                  <div key={field}>
                    <input
                      type="text"
                      name={field}
                      placeholder={`${field.replace(/([A-Z])/g, " $1").trim()}${
                        ["name", "bikeBrand", "bikeName"].includes(field)
                          ? " *"
                          : ""
                      }`}
                      value={formData[field]}
                      onChange={handleChange}
                      className="input-field"
                    />
                    {["name", "bikeBrand", "bikeName"].includes(field) &&
                      !formData[field] && (
                        <span className="error-text">
                          This field is required
                        </span>
                      )}
                  </div>
                ))}
                {["mobile", "altMobile"].map((field) => (
                  <div key={field}>
                    <input
                      type="text"
                      name={field}
                      placeholder={field === "mobile" ? "Mobile" : "Alt Mobile"}
                      value={formData[field]}
                      onChange={(e) => {
                        const value = e.target.value;
                        if (/^\d{0,10}$/.test(value)) {
                          setFormData({ ...formData, [field]: value });
                        }
                      }}
                      className="input-field"
                    />
                    <span className="error-text">
                      {formData[field].length > 0 && formData[field].length < 10
                        ? "Must be exactly 10 digits"
                        : ""}
                    </span>
                  </div>
                ))}
                <input
                  type="email"
                  name="email"
                  placeholder="Email"
                  value={formData.email}
                  onChange={(e) =>
                    setFormData({ ...formData, email: e.target.value })
                  }
                  className="input-field"
                />
                <span className="error-text">
                  {/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(formData.email) ||
                  formData.email === ""
                    ? ""
                    : "Invalid email format"}
                </span>
                <input
                  type="number"
                  name="bikeYear"
                  placeholder="Vehicle Year"
                  value={formData.bikeYear}
                  onChange={handleChange}
                  min="1900"
                  max={new Date().getFullYear()}
                  className="input-field"
                />
                <input
                  type="text"
                  name="bikeRegistrationNumber"
                  placeholder="Registration Number *"
                  value={registrationNumber}
                  disabled
                  className="input-field"
                />
                <div>
                  <button
                    onClick={handleSubmit}
                    disabled={
                      !formData.name ||
                      !formData.mobile ||
                      !formData.email ||
                      !formData.bikeBrand ||
                      !formData.bikeName ||
                      !registrationNumber
                    }
                    className="btn btn-primary"
                  >
                    Submit
                  </button>
                  <button
                    onClick={() => setShowCreateJobCardModal(false)}
                    className="btn btn-secondary"
                  >
                    Cancel
                  </button>
                </div>
              </div>
            </div>
          )}

          {/* Vehicle Details Modal */}
          {showDetailsModal && selectedBike && (
            <div className="modal-overlay" onClick={closeModal}>
              <div className="modal-content" onClick={(e) => e.stopPropagation()} style={{
                maxHeight: '80vh',
                overflowY: 'auto'
              }}>
                <h2>Vehicle Details</h2>
                <p>
                  <strong>ID:</strong> {selectedBike._id}
                </p>
                <p>
                  <strong>Registration:</strong>{" "}
                  {selectedBike.registrationNumber}
                </p>
                <p>
                  <strong>Brand:</strong> {selectedBike.brand}
                </p>
                <p>
                  <strong>Name:</strong> {selectedBike.name}
                </p>
                <p>
                  <strong>Year:</strong> {selectedBike.year}
                </p>
                <p>
                  <strong>Owner:</strong> {selectedBike.owner?.name} (
                  {selectedBike.owner?.email})
                </p>
                <p>
                  <strong>Created At:</strong>{" "}
                  {new Date(selectedBike.createdAt).toLocaleString()}
                </p>
                <p>
                  <strong>Updated At:</strong>{" "}
                  {new Date(selectedBike.updatedAt).toLocaleString()}
                </p>
                <p>
                  <strong>isDeleted:</strong>{" "}
                  {selectedBike.isDeleted ? "Yes" : "No"}
                </p>
                <div className="modal-btn-group">
                  <button
                    onClick={() => setShowDetailsModal(false)}
                    className="btn btn-secondary"
                  >
                    Close
                  </button>
                  <button
                    onClick={() => {
                      setJobCardData({
                        registrationNumber: selectedBike.registrationNumber,
                        brand: selectedBike.brand,
                        name: selectedBike.name,
                        year: selectedBike.year,
                        ownerEmail: selectedBike.owner?.email || "",
                      });
                      setShowJobCardCreationModal(true);
                      setShowDetailsModal(false);
                    }}
                    className="btn btn-primary"
                  >
                    Create Job Card
                  </button>
                </div>
              </div>
            </div>
          )}

          {/* Vehicle History Modal */}
          {showHistoryModal && (
            <div className="modal-overlay" onClick={closeModal}>
              <div className="modal-content" onClick={(e) => e.stopPropagation()}>
                <h2>Vehicle History</h2>
                {bikeHistory.length === 0 ? (
                  <p>No history found for this Vehicle.</p>
                ) : (
                  <table className="responsive-table">
                    <thead>
                      <tr>
                        <th>Timestamp</th>
                        <th>Event</th>
                      </tr>
                    </thead>
                    <tbody>
                      {bikeHistory.map((record, index) => (
                        <tr key={index}>
                          <td data-label="Timestamp">
                            {new Date(record.timestamp).toLocaleString()}
                          </td>
                          <td data-label="Event">{record.event}</td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                )}
                <button
                  onClick={() => setShowHistoryModal(false)}
                  className="btn btn-secondary"
                >
                  Close
                </button>
              </div>
            </div>
          )}

          {/* Create Job Card Modal */}
          {showJobCardCreationModal && (
            <div className="modal-overlay" onClick={closeModal}>
              <div className="modal-content" onClick={(e) => e.stopPropagation()}>
                <h2>Create Job Card</h2>
                <input
                  type="text"
                  name="registrationNumber"
                  placeholder="Registration Number"
                  value={jobCardData.registrationNumber}
                  onChange={(e) =>
                    setJobCardData({
                      ...jobCardData,
                      registrationNumber: e.target.value,
                    })
                  }
                  disabled
                  className="input-field"
                />
                <input
                  type="text"
                  name="brand"
                  placeholder="Brand"
                  value={jobCardData.brand}
                  onChange={(e) =>
                    setJobCardData({ ...jobCardData, brand: e.target.value })
                  }
                  className="input-field"
                />
                <input
                  type="text"
                  name="name"
                  placeholder="Name"
                  value={jobCardData.name}
                  onChange={(e) =>
                    setJobCardData({ ...jobCardData, name: e.target.value })
                  }
                  className="input-field"
                />
                <input
                  type="text"
                  name="year"
                  placeholder="Year"
                  value={jobCardData.year}
                  onChange={(e) =>
                    setJobCardData({ ...jobCardData, year: e.target.value })
                  }
                  className="input-field"
                />
                <input
                  type="email"
                  name="ownerEmail"
                  placeholder="Owner Email"
                  value={jobCardData.ownerEmail}
                  onChange={(e) =>
                    setJobCardData({
                      ...jobCardData,
                      ownerEmail: e.target.value,
                    })
                  }
                  className="input-field"
                />
                <div>
                  <button
                    onClick={handleJobCardSubmit}
                    className="btn btn-primary"
                  >
                    Submit
                  </button>
                  <button
                    onClick={() => setShowJobCardCreationModal(false)}
                    className="btn btn-secondary"
                  >
                    Cancel
                  </button>
                </div>
              </div>
            </div>
          )}

          {showSuccessPopup && (
            <div className="success-popup">
              Job card created successfully
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default CreateJobCard;
