import React from "react";

function Footer() {
  return (
    <div
      style={{
        backgroundColor: "rgb(41, 104, 171)",
        color: "#fff",
        padding: "20px 0",
        fontFamily: "Arial, sans-serif",
        marginBottom: "40px"
      }}
    >
      <div
        style={{
          display: "flex",
          justifyContent: "space-around",
          padding: "0 50px",
        }}
      >
        {/* Left Section - Logo and Contact Info */}
        <div style={{ flex: 1, textAlign: "left" }}>
          <img
            src="https://mrmechanic.co/static/media/mrmechanics-logo.1133374bdc3106a01cfd.png"
            alt="Logo"
            style={{ width: 230, height: "auto", marginTop: 40 }} // Adjust size as needed
          />
        </div>

        {/* Center Section - Services and Packages */}
        <div style={{ flex: 1 }}>
          <h3 style={{fontFamily: "initial",fontSize: 20, fontWeight: "400", color: "white", marginBottom: "15px" }}>
            COMPANY
          </h3>
          <ul style={{listStyleType: "none", padding: 0, lineHeight: "2" }}>
            <li style={{fontFamily: "initial", fontSize: 20, fontWeight: "300",}}>How it works</li>
            <li style={{fontFamily: "initial", fontSize: 20, fontWeight: "300",}}>Pricing</li>
            <li style={{fontFamily: "initial", fontSize: 20, fontWeight: "300",}}>Docs</li>
           
          </ul>
        </div>

        {/* Right Section - Mafia Packages */}
        <div style={{ flex: 1 }}>
        <h3 style={{fontFamily: "initial",fontSize: 20, fontWeight: "400", color: "white", marginBottom: "15px" }}>
        RESOURCES
          </h3>
          <ul style={{ listStyleType: "none", padding: 0, lineHeight: "2" }}>
          <li style={{fontFamily: "initial", fontSize: 20, fontWeight: "300",}}>The Bull</li>
          <li style={{fontFamily: "initial", fontSize: 20, fontWeight: "300",}}>The Iceman</li>
          <li style={{fontFamily: "initial", fontSize: 20, fontWeight: "300",}}>The Viper</li>
          </ul>
        </div>

        {/* Special Services and Franchise */}
        <div style={{ flex: 1 }}>
        <h3 style={{fontFamily: "initial",fontSize: 20, fontWeight: "400", color: "white", marginBottom: "15px" }}>
        ABOUT
        </h3>
          <ul style={{ listStyleType: "none", padding: 0, lineHeight: "2" }}>
          <li style={{fontFamily: "initial", fontSize: 20, fontWeight: "300",}}>Terms & Conditions</li>
          <li style={{fontFamily: "initial", fontSize: 20, fontWeight: "300",}}>Privacy policy</li>
          </ul>
        </div>
      </div>
    </div>
  );
}

export default Footer;
