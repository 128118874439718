import React from "react";
import { useNavigate } from "react-router-dom";
import CompanyLogo from "../assests/mrmechanics-logo.png";

function MainHeader() {
  const navigate = useNavigate();

  const handleLoginClick = () => {
    const token = localStorage.getItem("token");
    if (token) {
      navigate("/dashboard");
    } else {
      navigate("/login");
    }
  };

  return (
    <>
      <style>
        {`
          /* Top Bar */
          .top-bar {
            background-color: rgb(41, 104, 171);
            padding: 10px 20px;
          }
          .top-bar .phone-number {
            color: white;
            text-align: right;
            display: block;
            font-size: 14px;
          }

          /* Bottom Bar */
          .bottom-bar {
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding: 0px 20px;
            background-color: white;
          }
          .logo-container img {
            max-width: 200px;
            margin-top: 5px
          }
          .nav-links {
            display: flex;
            justify-content: center;
            gap: 100px;
          }
          .nav-links a {
            text-decoration: none;
            color: rgb(56, 59, 63);
            font-size: 20px;
          }
          .login-container button {
            background-color: rgb(41, 104, 171);
            color: white;
            border: none;
            padding: 10px 20px;
            cursor: pointer;
            font-size: 16px;
            border-radius: 8px;
          }

          /* Responsive Styles */
          @media (max-width: 768px) {
            .bottom-bar {
              flex-direction: column;
              text-align: center;
            }
            .nav-links {
              margin: 10px 0;
              flex-wrap: wrap;
            }
            .logo-container,
            .login-container {
              margin: 5px 0;
            }
          }
        `}
      </style>
      <header className="main-header">
        {/* Top Row: Blue bar with phone number */}
        <div className="top-bar">
          <span className="phone-number">Call us at: +91-80-100-44000</span>
        </div>
        {/* Bottom Row: Logo, Navigation, and Login */}
        <div className="bottom-bar">
          <div className="logo-container">
            <img src={CompanyLogo} alt="Company Logo" />
          </div>
          <nav className="nav-links">
            <a href="/aboutUs">About Us</a>
            <a href="/services">Our Services</a>
            <a href="/contact">Contact Us</a>
          </nav>
          <div className="login-container">
            <button onClick={handleLoginClick}>Login</button>
          </div>
        </div>
      </header>
    </>
  );
}

export default MainHeader;
