import React, { useEffect, useState } from "react";
import Navbar from "../components/Navbar";
import Sidebar from "../components/Sidebar";
import "./InventoryUpdates.css";

function InventoryUpdates() {
  const [updates, setUpdates] = useState([]);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(true);
  
  // Pagination state
  const [currentPage, setCurrentPage] = useState(1);
  const [updatesPerPage] = useState(10);
  
  // Single search term for filtering multiple fields
  const [searchTerm, setSearchTerm] = useState("");

  useEffect(() => {
    const fetchUpdates = async () => {
      const token = localStorage.getItem("token");

      if (!token) {
        setError("No token found. Please log in.");
        setLoading(false);
        return;
      }

      const myHeaders = new Headers();
      myHeaders.append("x-auth-token", token);

      try {
        const response = await fetch(
          "https://api.mrmechanic.co/api/inventory/updates",
          { method: "GET", headers: myHeaders, redirect: "follow" }
        );
        const result = await response.json();

        if (Array.isArray(result)) {
          // Sort updates by updateDate in descending order
          result.sort(
            (a, b) => new Date(b.updateDate) - new Date(a.updateDate)
          );
          setUpdates(result);
          setError(null);
        } else {
          console.error("Expected an array but got:", result);
          setUpdates([]);
          setError("Unexpected data format from server.");
        }
      } catch (error) {
        console.error("Error fetching updates:", error);
        setUpdates([]);
        setError("Error fetching updates.");
      } finally {
        setLoading(false);
      }
    };

    fetchUpdates();
  }, []);

  // Filter updates based on search term across multiple fields
  const filteredUpdates = updates.filter((update) => {
    const { itemName, company, category, updatedBy } = update;
    return (
      itemName.toLowerCase().includes(searchTerm.toLowerCase()) ||
      company.toLowerCase().includes(searchTerm.toLowerCase()) ||
      category.toLowerCase().includes(searchTerm.toLowerCase()) ||
      updatedBy.toLowerCase().includes(searchTerm.toLowerCase())
    );
  });

  // Pagination Logic
  const indexOfLastUpdate = currentPage * updatesPerPage;
  const indexOfFirstUpdate = indexOfLastUpdate - updatesPerPage;
  const currentUpdates = filteredUpdates.slice(
    indexOfFirstUpdate,
    indexOfLastUpdate
  );
  const totalPages = Math.ceil(filteredUpdates.length / updatesPerPage);

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  return (
      <div className="dashboard">
        <Sidebar />
        <div className="content">
          <h2>Inventory Updates</h2>

          {loading && <p className="loading-text">Inventory Updates loading...</p>}

          <div className="search-container">
            <input
              type="text"
              placeholder="Search by name, company, category, updated by"
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
              className="search-input"
            />
          </div>

          <table className="inventory-table">
            <thead>
              <tr className="table-header">
                <th className="table-cell">Item Name</th>
                <th className="table-cell">Company</th>
                <th className="table-cell">Category</th>
                <th className="table-cell">Subcategories</th>
                <th className="table-cell">Updated By</th>
                <th className="table-cell">Update Details</th>
                <th className="table-cell">Update Date-Time</th>
              </tr>
            </thead>
            <tbody>
              {currentUpdates.length > 0 ? (
                currentUpdates.map((update, index) => (
                  <tr key={index} className="table-row">
                    <td className="table-cell">{update.itemName}</td>
                    <td className="table-cell">{update.company}</td>
                    <td className="table-cell">{update.category}</td>
                    <td className="table-cell">
                      {update.subCategories.map((sub, i) => (
                        <div key={i}>{sub}</div>
                      ))}
                    </td>
                    <td className="table-cell">{update.updatedBy}</td>
                    <td className="table-cell">{update.updateDetails}</td>
                    <td className="table-cell">
                      {new Date(update.updateDate).toLocaleString()}
                    </td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td colSpan="7" className="table-cell">
                    No updates available
                  </td>
                </tr>
              )}
            </tbody>
          </table>

          <div className="pagination">
            {Array.from({ length: totalPages }, (_, index) => (
              <button
                key={index}
                onClick={() => handlePageChange(index + 1)}
                className={`page-btn ${currentPage === index + 1 ? "active" : ""}`}
              >
                {index + 1}
              </button>
            ))}
          </div>
          {error && <p className="error-text">{error}</p>}
        </div>
      </div>
  );
}

export default InventoryUpdates;
